<template src="./AccountInfo.html"/>

<script>
import ConnectStripeBtn from '@/components/ConnectStripeBtn.vue'
import ClientInformationCmp from "@/components/ClientInformationCmp.vue";
import TabLayout from "@/components/UIAccessories/TabLayout.vue";

export default {
  name: 'AccountInfo',
  components: {
    TabLayout,
    ClientInformationCmp,
    ConnectStripeBtn
  },
  data () {
    return {
      stripePayments: {
        loading: true,
        onboardingComplete: false,
        isAcceptingPayments: false,
        country: null, // not used but returned from api
        stripeEnabled: null
      }
    }
  },
  mounted () {
    this.getClientPaymentStatus()
  },

  methods: {
    async getClientPaymentStatus () {
      this.stripePayments.loading = true
      const response = await this.$http.payment.get('ft-clients/status')
      this.stripePayments = response.data
      this.stripePayments.loading = false
    }
  }
}
</script>
