<template>
<div id="active-devices" class="ma-6">
  <div class="text-center mt-2 mb-4">
    <span class="display-3">
      Virtual Key transactions
    </span>
  </div>
  <div class="text-center mt-2 mb-4">
    <span class="text-h6">
      {{userDetails.name}} {{userDetails.surname}} - {{userDetails.email}}
    </span>
  </div>
  <div class="text-center mt-2 mb-4" v-if="$store.getters.isAdmin">
    User id: {{userId}}
  </div>

  <div class="text-center">
    <span class="font-weight-bold">Azioni:</span>
    <v-tooltip top>
      <template v-slot:activator="{on, attrs}">
        <v-btn large icon color="primary"
               v-bind="attrs"
               v-on="on"
               @click="topUpVK()"
               class="mr-3"
        >
          <v-icon>
            fa-plus
          </v-icon>
        </v-btn>
      </template>
      <span>Ricarica utente</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{on, attrs}">
        <v-btn v-if="$store.getters.isAdmin"
               large icon color="primary"
               v-bind="attrs"
               v-on="on"
               @click="chargeVK()"
        >
          <v-icon>
            fa-minus
          </v-icon>
        </v-btn>
      </template>
      <span>Charge user</span>
    </v-tooltip>
  </div>

  <v-data-table
      :headers="headers"
      :items="transactions"
      item-key="userId"
      sort-by="name"
      class="elevation-1 text-center mt-3"
  >
    <template v-slot:item.id="{ value }">
      <v-tooltip bottom v-if="value">
        <template v-slot:activator="{ on }">
          {{getLast4Digits(value)}}
          <v-chip small link outlined v-on="on" @click="copyTextToClipboard(value)">Copy ID</v-chip>
        </template>
        <span>
            {{value}}
          </span>
      </v-tooltip>
    </template>

    <template v-slot:item.serialNumber="{ value, item }">
      <v-chip v-if="value" link outlined :to="`/devices/${value}`">{{ value }}</v-chip>
      <span v-if="value && $store.getters.isAdmin">{{item.macAddress}}</span>
    </template>
    <template v-slot:item.amount="{ value, item }">
      {{ formatMoneyWithSign(value, item) }}
      <span v-if="item.purchaseAmount && value != item.purchaseAmount.full" class="text-caption ml-1">
        ({{formatMoney(item.purchaseAmount.full)}})
      </span>
    </template>
    <template v-slot:item.datetime="{ value }">
      <span v-if="$store.getters.isAdmin">{{ formatDateTime(value) }}</span>
      <span v-else>{{ formatDateNoSecs(value) }}</span>
    </template>
    <template v-slot:item.type="{ value }">
      {{ getTransTypeMsgByCode(value) }}
    </template>

    <template v-slot:item.refunded="{ value, item }">
      <div v-if="value">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              Sì
              <span v-if="$store.getters.isAdmin">: {{ getReasonCode(item.refundReason) }} ({{ item.refundReason }})</span>
            </span>
          </template>
          <span>
            Rimborsato il:<br>
            <b>{{ formatDateTime(item.refundDatetime) }}</b>
          </span>
        </v-tooltip>
      </div>
      <div v-else-if="item.refundedTransactionId">
        <span v-if="!$store.getters.isAdmin">Rimborso</span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            {{getLast4Digits(item.refundedTransactionId)}}
            <v-chip small link outlined v-on="on" @click="copyTextToClipboard(item.refundedTransactionId)">Copy refunded ID</v-chip>
          </template>
          <span>
            {{value}}
          </span>
        </v-tooltip>
      </div>
      <div v-else>-</div>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn v-if="item.type === 'PURCHASE_VK'"
             :disabled="item.refunded || item.disabled"
             small text color="primary"
             @click="refundTransaction(item)"
      >
        Rimborsa
      </v-btn>
    </template>

    <template v-slot:no-data>
      <v-btn
          color="primary"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>

  <top-up-virtual-key-modal
      :ft-client-id="ftClientId"
      :user.sync="userTopUpModal"
      @close="reloadDelayed"
  ></top-up-virtual-key-modal>
  <charge-virtual-key-modal
      v-if="$store.getters.isAdmin"
      :ft-client-id="ftClientId"
      :user.sync="userChargeModal"
      @close="reloadDelayed"
  ></charge-virtual-key-modal>
</div>
</template>

<script>
import ChargeVirtualKeyModal from "@/components/modals/administration/ChargeVirtualKeyModal.vue";
import RefundVirtualKeyModal from "@/components/modals/commons/TopUpVirtualKeyModal.vue";
import foodytekUtils from "@/mixins/foodytekUtils";
import TopUpVirtualKeyModal from "@/components/modals/commons/TopUpVirtualKeyModal.vue";

export default {
  name: 'UsersTransactions',
  components: { TopUpVirtualKeyModal, ChargeVirtualKeyModal },
  mixins: [foodytekUtils],
  data () {
    return {
      userDetails: {},
      transactions: [],
      userTopUpModal: null,
      userChargeModal: null,
      headers: [
        {
          text: 'Id',
          value: 'id',
          filterable: false,
          hide: !this.$store.getters.isAdmin
        },
        {
          text: 'Data e ora',
          value: 'datetime',
          filterable: false
        },
        {
          text: 'Dispositivo',
          value: 'serialNumber',
          filterable: false
        },
        {
          text: 'Importo (prezzo non scontato tra parentesi)',
          value: 'amount',
          filterable: false
        },
        {
          text: 'Rimborsato',
          value: 'refunded'
        },
        {
          text: 'Tipo',
          value: 'type'
        },
        {
          text: 'Azioni',
          value: 'actions'
        }
      ].filter(h => !h.hide)
    }
  },
  computed: {
    ftClientId () {
      return this.$route.params.ftClientId
    },
    userId () {
      return this.$route.params.userId
    }
  },
  mounted () {
    this.reload()
  },
  methods: {
    reloadDelayed () {
      setTimeout(this.reload, 600)
    },
    reload () {
      this.getUserDetails()
      this.getTransactions()
    },
    async getTransactions () {
      try {
        const response = await this.$http.payment(`virtual-keys/admin/${this.ftClientId}/${this.userId}/transactions`)
        this.transactions = response.data
      } catch (e) {
      }
    },
    async getUserDetails () {
      try {
        const response = await this.$http.auth(`queries/users/${this.userId}`)
        this.userDetails = response.data
      } catch (e) {
      }
    },
    async refundTransaction (item) {
      item.disabled = true
      const res = await this.$dialog.confirm({
        title: 'Rimborso transazione',
        text: 'Sei sicuro di voler rimborsare questa transazione? Importo: ' + this.formatMoney(item.amount),
      })
      if (!res) return

      await this.$http.payment.patch(
          `virtual-keys/admin/${this.ftClientId}/${this.userId}/transactions/${item.id}/refund`
      )
      setTimeout(this.getTransactions, 600)
      await this.getTransactions()
    },
    topUpVK () {
      this.userTopUpModal = this.userDetails
    },
    chargeVK () {
      this.userChargeModal = this.userDetails
    },
    getLast4Digits (text) {
      return '...' + text.slice(-4)
    },
    formatMoneyWithSign(value, item) {
      const negative = item.type === 'PURCHASE_VK'
      return this.formatMoney(negative ? -value : value)
    },
  }
}
</script>

<style scoped>

</style>
