var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h4"},[(_vm.isAdmin)?_c('span',[_vm._v("Devices management")]):_c('span',[_vm._v("I miei dispositivi")])]),(_vm.isAdmin)?_c('div',[_c('div',{staticClass:"text-overline text-left"},[_vm._v(" Select the columns to show: "),_c('v-checkbox',{staticClass:"d-inline-block mx-2 mt-0",attrs:{"label":"Select all"},on:{"change":function (newVal) { return _vm.headers.forEach(function (item) { return item.visible = newVal; }); }}})],1),_vm._l((_vm.headers),function(column){return _c('v-checkbox',{key:column.value,staticClass:"d-inline-block mx-2 mt-0",attrs:{"label":column.text},on:{"change":_vm.updateHeadersVisibility},model:{value:(column.visible),callback:function ($$v) {_vm.$set(column, "visible", $$v)},expression:"column.visible"}})})],2):_vm._e(),_c('v-text-field',{staticClass:"mt-3 mb-6 mx-5",attrs:{"append-icon":"mdi-magnify","label":"Cerca tra i dispositivi...","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"text-center",attrs:{"headers":_vm.visibleHeaders,"items":_vm.devices,"search":_vm.search,"custom-filter":_vm.customFilter,"item-key":"serialNumber","sort-by":"serialNumber","sort-desc":""},scopedSlots:_vm._u([{key:"item.serialNumber",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"link":"","outlined":"","to":("/devices/" + value)}},[_vm._v(_vm._s(value))])]}},{key:"item.active",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value ? 'green' : 'red'}},[_vm._v(_vm._s(value ? 'fa-check' : 'fa-times'))])]}},{key:"item.configured",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value ? 'green' : 'red'}},[_vm._v(_vm._s(value ? 'fa-check' : 'fa-times'))])]}},{key:"item.ftClient",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value.id)}}},on),[_vm._v(_vm._s(value.businessName))])]}}],null,true)},[_c('span',[_vm._v("Click to copy id "),_c('br'),_vm._v(" "),_c('b',[_vm._v(_vm._s(value.id))])])]):_vm._e()]}},{key:"item.partner",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',[(_vm.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value.id)}}},on),[_vm._v(_vm._s(value.name))])]}}],null,true)},[_c('span',[_vm._v("Click to copy id "),_c('br'),_vm._v(" "),_c('b',[_vm._v(_vm._s(value.id))])])]):_c('v-chip',{attrs:{"link":"","outlined":"","to":'/partners/' + value.id}},[_vm._v(_vm._s(value.name))])],1):_vm._e()]}},{key:"item.location",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',[(_vm.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value.id)}}},on),[_vm._v(_vm._s(value.name))])]}}],null,true)},[_c('span',[_vm._v("Click to copy id "),_c('br'),_vm._v(" "),_c('b',[_vm._v(_vm._s(value.id))])])]):_c('v-chip',{attrs:{"link":"","outlined":"","to":'/locations/' + value.id}},[_vm._v(_vm._s(value.name))])],1):_vm._e()]}},{key:"item.lastVersionUpdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateNoSecs(item.lastVersionUpdate))+" ")]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateNoSecs(item.creationDate))+" ")]}},{key:"item.assignmentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateNoSecs(item.assignmentDate))+" ")]}},{key:"item.activationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateNoSecs(item.activationDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.isAdmin && !item.ftClient)?_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.openAssignDeviceModal(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-user-check")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openQRCode(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-qrcode ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download QR-Code")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.copyNFCUrl(item.macAddress)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-brands fa-nfc-symbol ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy NFC Tag URL")])])],1)]}}])})],1),_c('DeviceQRCodeModal',{model:{value:(_vm.qrCode),callback:function ($$v) {_vm.qrCode=$$v},expression:"qrCode"}}),_c('assign-device-modal',{attrs:{"serial-number":_vm.assignDeviceSerialNumber},on:{"update:serialNumber":function($event){_vm.assignDeviceSerialNumber=$event},"update:serial-number":function($event){_vm.assignDeviceSerialNumber=$event},"close":_vm.reload}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"green","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" NFC Tag URL copied to clipboard ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }