<template src="./InstallersManagement.html"/>

<script>
import RegistrationLinkModal from '@/components/modals/commons/RegistrationLinkModal.vue'

export default {
  name: 'InstallersManagement',
  components: { RegistrationLinkModal },
  data () {
    return {
      users: [],
      tokens: [],
      search: '',
      registrationModal: false
    }
  },
  computed: {
    tokenHeaders () {
      return [
        { text: 'Email', value: 'email' },
        { text: '', value: 'crud' }
      ]
    },
    headers () {
      return [
        { text: 'id', value: 'id' },
        { text: this.$t('commons.data.name'), value: 'name' },
        { text: this.$t('commons.data.surname'), value: 'surname' },
        { text: 'Email', value: 'email' },
        { text: '', value: 'crud' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getRegistrationTokens()
      this.getUsers()
    },
    reload () {
      setTimeout(this.init, 200)
    },
    async getUsers () {
      try {
        const response = await this.$http.auth.get('/queries/users/role/ft-client-installers')
        this.users = response.data
      } catch (e) {
      }
    },
    async getRegistrationTokens () {
      try {
        const response = await this.$http.auth.get('queries/registration-tokens/ft-client/installers')
        this.tokens = response.data
      } catch (e) {
      }
    },

    removeUser (item) {
      try {
        this.$dialog.confirmation.deletion(this.$t('usersManagement.installers.removeMessage', { email: item.email }), this.$t('usersManagement.remove'))
        this.$http.auth.delete('commands/users/downgrade/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
    async revokeToken (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('usersManagement.revokeTokenMessage', { email: item.email }), this.$t('usersManagement.revoke'))
        await this.$http.auth.delete('commands/registration-tokens/' + item.id)
        this.reload()
      } catch (e) {
      }
    }
  }
}
</script>
