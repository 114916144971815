<template>
  <action-modal
      v-model="modalVisible"
      :data="creationModal"
      :cancel-btn-text="$t('commons.cancel')"
      :loading-text="$t('commons.loading')"
      :submit-title="$t('registrationLinkModal.title')"
      :submit-success-message="$t('registrationLinkModal.successfulMessage')"
      :submit-btn-text="$t('registrationLinkModal.sendTokenEmailBtn')"
      :fields="[{
          label: 'Email',
          value: 'email',
          rules: 'required|email',
          cols: '12'
      }]"
      :submit-promise-callback="getTokenCallback"
      @close="closeModal"
  />
</template>

<script>

import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'RegistrationLinkModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    userRole: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      creationModal: {
        email: null
      }
    }
  },
  computed: {
    modalVisible: {
      get () { return this.value },
      set (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    getTokenCallback () {
      return this.$http.auth.post(
        'commands/registration-tokens/' + this.userRole,
        { email: this.creationModal.email }
      ).then((response) => {
        const token = response.data
        this.showTemporaryDialogToCopyLink(token)
        this.closeModal()
      })
    },
    async showTemporaryDialogToCopyLink (token) {
      const baseUrl = this.$env.isLocalhost
        ? 'http://localhost:8080'
        : 'https://login' + (this.$env.isDev ? '.dev' : '') + '.foodytek.com'
      const url = baseUrl + '/register/' + token

      const env = this.$env.isLocalhost ? 'Locale' : this.$env.isDev ? 'Development' : 'Production'
      const additionalMsg = this.$store.getters.isAdmin || this.$env.isLocalhost || this.$env.isDev
        ? `P.S.: Ho riconosciuto che sei in <b>${env}</b>! (so che te lo stai chiedendo... E la risposta è sì, quest'ultima frase non la vedono i nostri clienti ;)`
        : ''

      const that = this
      await this.$dialog.confirm({
        title: 'Link di registrazione',
        text: `Al momento non viene ancora inviata una mail automatica.
                   Invia tu una mail a chi deve essere invitato per far procedere questa persona con la sua registrazione.<br><br>
                Clicca <b>"Copia link"</b> e incolla il link nel tuo programma di posta.<br><br>` + additionalMsg,
        icon: false,
        actions: [
          {
            text: 'Annulla'
          },
          {
            text: 'Copia link',
            icon: 'fa-copy',
            color: 'primary',
            outlined: true,
            handler () {
              that.copyTextToClipboard(url)
              that.$dialog.notify.success('Link copiato negli appunti. Ora puoi incollarlo nella mail.')
            }
          }
        ]
      })
    },
    closeModal () {
      this.createModal = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
