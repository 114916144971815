<script>

import FtColInfoView from "@/components/UIAccessories/FtColInfoView.vue";

export default {
  name: "RemoteTopUpDetails",
  components: { FtColInfoView },
  data () {
    return {
      topUp: null,
      users: [],
      userHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Cognome', value: 'surname' },
        { text: 'Email', value: 'email' },
      ],
    }
  },
  mounted () {
    this.fetchTopUps()
  },
  methods: {
    async fetchTopUps () {
      const response = await this.$http.payment.get(`/remote-top-ups/${this.$route.params.topUpId}`)
      this.topUp = response.data
      this.users = response.data.users
    },
  },
}
</script>

<template>
  <div v-if="topUp">
    <h1>Utenti della ricarica remota del {{ formatDateNoSecs(topUp.created) }}</h1>

<!--    {{topUp}}-->

    <v-card style="max-width: 55rem" class="mx-auto my-9">
      <v-row>
        <ft-col-info-view label="Data e ora" :value="formatDateNoSecs(topUp.created)"/>
        <ft-col-info-view label="Ricarica per utente" :value="formatMoney(topUp.amountPerUser)"/>
        <ft-col-info-view label="Ricarica totale" :value="formatMoney(topUp.totalAmount)"/>
      </v-row>
    </v-card>

    <v-data-table
        :headers="userHeaders"
        :items="users"
        sort-by="email"
    />
  </div>
</template>
