<template>
  <v-menu
      ref="monthMenu"
      v-model="monthMenu"
      :return-value.sync="date"
      transition="slide-y-transition"
      offset-y
      max-width="290px"
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="date"
          :label="label"
          prepend-icon="fas fa-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        type="month"
        no-title
        scrollable
        @change="$refs.monthMenu.save(date); monthSelected()"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'FtMonthSelect',
  props: {
    label: String,
    previousMonthDefault: Boolean
  },
  data () {
    return {
      monthMenu: false,
      date: ''
    }
  },
  mounted () {
    if (!this.date) {
      const now = new Date()
      let year = now.getFullYear()
      let month = now.getMonth() + 1 // It starts from 0 but needed from 1
      if (this.previousMonthDefault) {
        month--
        if (month === 0) {
          month = 12
          year--
        }
      }

      this.date = year + '-' + month
      this.$emit('month-selected', { year, month })
    }
  },
  methods: {
    async monthSelected () {
      const [year, month] = this.date.split('-')
      this.$emit('month-selected', { year, month })
    }
  }
}
</script>

<style scoped>

</style>
