<script>
export default {
  name: "ClientCustomerCompleteReg",
  data () {
    return {
      loading: Boolean(this.$route.query.loading) || false
    }
  },
  mounted () {
    if (this.loading) {
      setTimeout(this.reloadRegStatus, 800)
    }
  },
  methods: {
    async openStripeCheckout() {
      try {
        this.loading = true
        const res = await this.$http.payment.post('/client-customers/stripe-payment-methods', {
          returnUrl: location.href + '?loading=true'
        })

        window.location = res.data;
      } catch (e) {
        this.loading = false
      }
    },
    async reloadRegStatus() {
      await this.$store.dispatch('updateRegComplete')
      if (this.$store.getters.ccRegComplete) {
        await this.$router.push('/')
      } else {
        // fixme: logger saving to api -> should not arrive here
      }
    }
  }
}
</script>

<template>
  <div>
    <h1>Ultimo passaggio: aggiungi un IBAN per effettuare gli addebiti mensili</h1>

    <v-alert color="secondary" class="lighten-4 mt-4 mb-6">
      <h4>Perchè aggiungere l'IBAN?</h4>
      Il conto che viene collegato sarà usato per effettuare il pagamento complessivo a fine mese per tutte le ricariche
      effettuate sui portafogli virtuali. L'addebito sarà completamente automatico utilizzando il metodo SEPA Direct.
      <br>
      Accedendo con le credenziali che hai inserito nel precedente passaggio, potrai
      accedere a questa dashboard per effettuare le ricariche, monitorare i pagamenti effettuati e modificare l'IBAN da
      utilizzare anche in un secondo momento.
    </v-alert>

    <div class="text-center">
      <v-btn @click="openStripeCheckout" color="primary" outlined :loading="loading">Aggiungi IBAN</v-btn>
    </div>
  </div>
</template>

<style scoped>

</style>