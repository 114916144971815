<script>
export default {
  name: 'AmountProgressCircular',
  props: {
    amount: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    percent () {
      if (this.total === 0) return 0
      return Math.round(this.amount / this.total * 10000) / 100;
    }
  }
}
</script>

<template>
  <div>
    <v-progress-circular :value="percent" :size="100" :color="color" rotate="-90" width="15">
      {{ percent }}%
    </v-progress-circular>
    <div class="text-center mt-2">
      <span class="font-weight-bold body-1">{{ formatMoney(amount) }}</span> <br>
      {{ text }}
    </div>
  </div>
</template>

<style scoped>

</style>