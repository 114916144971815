<template>
  <div v-if="group">
    <v-card class="mt-8 elevation-8 mx-auto mb-7" style="width: 90%">
      <v-card-title class="d-block text-center pt-6 pb-5 text-h4">
        Gruppo "{{group.name}}"
      </v-card-title>
      <v-card-text v-if="group.notes" class="text-body-1">
        <b>Note:</b> {{group.notes}}
      </v-card-text>

      <v-card-text>
        <h2 class="mb-3 text-h4">Utenti</h2>
        <div class="d-flex align-center mb-4 mx-5">
          <v-text-field
              v-model="search"
              append-icon="fa-search"
              outlined
              :label="$t('commons.search')"
              class="mr-6"
              single-line
              hide-details/>
          <email-user-group-modal
              :group-id="group.id"
              @close="reload"
          />
        </div>

        <v-data-table
            :items="group.users"
            :headers="headers"
            :search="search"
            item-key="item.id"
            no-data-text="Non c'è ancora nessun utente. Crea il primo ora!"
        >
          <template v-slot:item.crud="{ item }">
            <v-btn color="red"
                   class="pa-5"
                   small icon
                   @click="removeUser(item)">
              <v-icon small>fa-user-minus</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-card class="mx-4 my-6" outlined v-if="group.usersNotReg.length">
          <v-card-title>
            Utenti aggiunti ma non ancora registrati alla piattaforma
          </v-card-title>
          <v-data-table
              :items="group.usersNotReg"
              :headers="uNotRegHeaders"
              :search="search"
              item-key="item.id"
              no-data-text="Non c'è ancora nessun utente. Crea il primo ora!"
          >
            <template v-slot:item.crud="{ item }">
              <v-btn color="red"
                     class="pa-5"
                     small icon
                     @click="removeUserNotReg(item)">
                <v-icon small>fa-user-minus</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>

    <user-group-modal
        v-model="groupModal.opened"
        :data="groupModal.item"
        :edit-mode="groupModal.editMode"
        @close="reload"
    />
  </div>
</template>

<script>
import UserGroupModal from "@/components/modals/business/UserGroupModal.vue";
import EmailUserGroupModal from "@/components/modals/business/EmailUserGroupModal.vue";

export default {
  name: 'UserGroupDetails',
  components: { EmailUserGroupModal, UserGroupModal },
  data () {
    return {
      group: null,
      search: '',
      userModal: {
        opened: false,
        editMode: false,
        item: {}
      },
      groupModal: {
        opened: false,
        editMode: false,
        item: {}
      }
    }
  },
  computed: {
    tokenHeaders () {
      return [
        { text: 'Email', value: 'email' },
        { text: '', value: 'crud' }
      ]
    },
    uNotRegHeaders () {
      return [
        { text: 'Email', value: 'email' },
        { text: '', value: 'crud' }
      ]
    },
    headers () {
      return [
        { text: 'Email', value: 'email' },
        { text: 'Nome', value: 'name' },
        { text: 'Cognome', value: 'surname' },
        { text: '', value: 'crud' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getGroupInfo()
    },
    reload () {
      setTimeout(this.init, 200)
    },
    async getGroupInfo () {
      try {
        const response = await this.$http.auth.get('/user-groups/' + this.$route.params.groupId)
        this.group = response.data
      } catch (e) {
      }
    },

    addUser() {
      this.userModal.editMode = false
      this.userModal.opened = true
    },
    async removeUser(item) {
      try {
        const res = await this.$dialog.confirm({
          title: 'Rimuovi utente',
          text: 'Sei sicuro di voler rimuovere l\'utente dal gruppo?',
        })
        if (!res) return
        await this.$http.auth.delete('/user-groups/' + this.group.id + '/users/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
    async removeUserNotReg(item) {
      try {
        const res = await this.$dialog.confirm({
          title: 'Rimuovi utente',
          text: 'Sei sicuro di voler rimuovere l\'utente dal gruppo?',
        })
        if (!res) return
        await this.$http.auth.delete('/user-groups/' + this.group.id + '/users-not-reg/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
    edit() {
      this.groupModal.editMode = true
      this.groupModal.item = {
        name: this.group.name,
        notes: this.group.notes
      }
      this.groupModal.opened = true
    },
  }
}
</script>
