var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tab-layout',{attrs:{"title":"Funzioni gruppi utenti"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addFeature}},[_vm._v(" Aggiungi ")])]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userGroupFeatures,"items-per-page":5},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.featureTypes[ value ])+" ")]}},{key:"item.userGroup",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"outlined":"","to":'/groups-management/' + value.id}},[_vm._v(_vm._s(value.name))])]}},{key:"item.discount",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(value)+"%")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editFeature(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFeature(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)]}}])}),_c('group-feature-for-device-modal',{attrs:{"data":_vm.modal.data,"edit-mode":_vm.modal.editMode,"serialNumber":_vm.serialNumber},on:{"close":_vm.loadUserGroupFeatures},model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }