<template>
<div id="active-devices" class="ma-6">
  <v-row class="justify-center mt-2 mb-4">
    <span class="display-3">
      Users wallet
    </span>
  </v-row>

  <v-autocomplete
      v-model="ftClientId"
      :items="ftClients"
      item-text="displayName"
      item-value="id"
      clearable
      label="Select a FoodyTek Client"
  ></v-autocomplete>

  <user-virtual-keys-table
      v-if="ftClientId"
      :ft-client-id="ftClientId"
  />
</div>
</template>

<script>
import UserVirtualKeysTable from "@/components/UserVirtualKeysTable.vue";

export default {
  name: 'UsersWallet',
  components: { UserVirtualKeysTable },
  data () {
    return {
      ftClients: [],
    }
  },
  computed: {
    ftClientId: {
      get () {
        return this.$route.params.ftClientId
      },
      set (value) {
        this.$router.push('/users-wallet/' + (value ?? ''))
      }
    }
  },
  mounted () {
    this.getFTClients()
  },
  methods: {
    async getFTClients () {
      try {
        const response = await this.$http.auth('ft-clients/lite')
        this.ftClients = response.data
      } catch (e) {
      }
    },
  }
}
</script>

<style scoped>

</style>
