var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('action-modal',{attrs:{"data":_vm.data,"edit-mode":_vm.editMode,"submit-btn-text":_vm.$t('commons.create'),"edit-btn-text":_vm.$t('commons.edit'),"cancel-btn-text":_vm.$t('commons.cancel'),"loading-text":_vm.$t('commons.loading'),"submit-title":_vm.$t('groupFeatures.modal.createTitle'),"edit-title":_vm.$t('groupFeatures.modal.editTitle'),"submit-success-message":_vm.$t('groupFeatures.messages.created'),"edit-success-message":_vm.$t('groupFeatures.messages.edited'),"fields":_vm.fields,"field-default-options":_vm.fieldDefaultOptions,"submit-promise-callback":_vm.createLocationCallback,"edit-promise-callback":_vm.editLocationCallback},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"field.type",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"items":_vm.features,"outlined":"","label":label,"error-messages":errors},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}})]}},{key:"field.userGroupId",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('div',[_c('v-select',{class:classes,attrs:{"items":_vm.userGroups,"outlined":"","label":label,"error-messages":errors},model:{value:(_vm.data.userGroupId),callback:function ($$v) {_vm.$set(_vm.data, "userGroupId", $$v)},expression:"data.userGroupId"}})],1)]}},{key:"field.discount",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('div',[_c('v-text-field',{class:classes,attrs:{"items":_vm.userGroups,"outlined":"","messages":"Utilizza il punto e non la virgola per i decimali.","label":label,"error-messages":errors},model:{value:(_vm.data.discount),callback:function ($$v) {_vm.$set(_vm.data, "discount", $$v)},expression:"data.discount"}})],1)]}}]),model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }