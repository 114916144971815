<template>
  <action-modal
      v-if="show"
      v-model="show"
      :data="obj"
      cancel-btn-text="Annulla"
      loading-text="Ricarica..."
      :submit-title="`Fai una ricarica per ${user.email}`"
      submit-success-message="Chiavetta ricaricata con successo!"
      submit-btn-text="Ricarica"
      :submit-promise-callback="topUpVK"
      :fields="fields"
  >
    <template #field.amount="{ errors, label, classes }">
      <price-input
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.amount"
      />
    </template>
    <template #field.reason="{ errors, label, classes }">
      <v-select
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.reason"
          :items="reasonTypes"
      />
    </template>
    <template #field.giveAutoGift>
      <v-checkbox v-model="obj.giveAutoGift"
                  :disabled="giveGiftCheckboxDisabled"
                  class="mt-n4"
                  :label="`Ricarica anche ${formatMoney(giftAmount)} come regalo`"/>
    </template>
  </action-modal>
</template>

<script>
import {ActionModal} from '@simple-it/shared-vuetify'
import PriceInput from "@/components/fields/PriceInput.vue";

export default {
  name: 'TopUpVirtualKeyModal',
  components: { PriceInput, ActionModal },
  props: {
    ftClientId: String,
    user: Object
  },
  data () {
    return {
      giveGift: true,
      thresholds: [],
      reasonTypes: [
        {
          text: 'Ricarica manuale (es. pagamento in ufficio, bonifico, ...)',
          value: 'MANUAL'
        },
        {
          text: 'Ricarica regalo',
          value: 'GIFT'
        }
      ],
      obj: {
        amount: null,
        reason: null,
        giveAutoGift: true
      },
      refundReasons: [
        'User request',
        'Device malfunction',
        'Other'
      ]
    }
  },
  computed: {
    fields () {
      return [
        {
          label: 'Importo',
          value: 'amount',
          rules: 'required|min:1',
          cols: '12',
          sm: '6'
        },
        {
          label: 'Motivo ricarica',
          value: 'reason',
          rules: 'required',
          cols: '12',
          sm: '6'
        },
        {
          label: 'Ricarica anche come regalo',
          value: 'giveAutoGift',
          rules: 'required',
          cols: '12',
          hide: !this.showGiveGiftCheckbox
        }
      ].filter(f => !f.hide)
    },
    show: {
      get () {
        return this.user != null
      },
      set () {
        this.$emit('update:user', null)
        this.$emit('close')
      }
    },
    showGiveGiftCheckbox () {
      return this.obj.reason === 'MANUAL'
          && this.thresholds.length > 0
    },
    giveGiftCheckboxDisabled () {
      return this.applicableThresholds.length === 0
    },
    giftAmount () {
      if (this.giveGiftCheckboxDisabled) return 0

      const gifts = this.applicableThresholds
          .map(t => {
            if (t.type === 'EURO') {
              return t.value
            } else {
              return this.obj.amount * t.value / 10000
            }
          })
      return Math.max(...gifts)
    },
    applicableThresholds () {
      return this.thresholds.filter(t => this.obj.amount >= t.amount)
    }
  },
  mounted () {
  },
  watch: {
    user () {
      if (this.user) this.init()
    }
  },
  methods: {
    init () {
      this.obj = {
        amount: null,
        reason: null,
        giveAutoGift: true
      }
      this.fetchThresholds()
    },
    topUpVK () {
      return this.$http.payment.post(
          `virtual-keys/admin/${this.ftClientId}/${this.user.id}/top-up/dashboard`,
          this.obj
      )
    },
    async fetchThresholds () {
      const res = await this.$http.payment.get(`/top-ups/auto-gifts/${this.ftClientId}/thresholds`, {
        params: {
          userId: this.user.id
        }
      })
      this.thresholds = res.data
    },
  }
}
</script>
