<script>
import PastSepaPaymentsTable from "@/components/PastSepaPaymentsTable.vue";

export default {
  name: "RemoteTopUps",
  components: { PastSepaPaymentsTable },
  data () {
    return {
      monthPaymentsHeaders: [
        { text: 'Cliente', value: 'businessName' },
        { text: 'Totale', value: 'remoteTopUpsTotal' },
      ].map(i => ({ ...i, sortable: false })),
      monthPayments: []
    }
  },
  mounted () {
    this.fetchCurrentPayments()
  },
  methods: {
    async fetchCurrentPayments () {
      const response = await this.$http.payment.get('/remote-top-ups/by-client/' + this.$store.getters.clientId)
      this.monthPayments = response.data
    }
  },
}
</script>

<template>
  <div>
    <h1>Ricariche remote</h1>

    <v-card class="my-6" outlined>
      <v-card-title>Ricariche dei clienti mese corrente (saranno addebitate i primi giorni del mese successivo)</v-card-title>

      <v-card-text v-if="monthPayments">
        Totale ricariche del mese ad oggi: {{ formatMoney(monthPayments.reduce((acc, item) => acc + item.remoteTopUpsTotal, 0)) }}
      </v-card-text>

      <v-data-table
          :headers="monthPaymentsHeaders"
          :items="monthPayments"
      >
        <template v-slot:item.clientCustomer="{ value }">
          {{ value.businessName }}
          <!--         todo: add link to client when create the details page -->
          <!--          <v-chip link outlined :to="`/users-wallet/${$store.getters.clientId}/transactions/${value.id}`">{{ value.email }}</v-chip>-->
        </template>
        <template v-slot:item.remoteTopUpsTotal="{ value }">
          {{ formatMoney(value) }}
        </template>
      </v-data-table>
    </v-card>

    <v-card class="my-6" outlined>
      <v-card-title>Addebiti SEPA passati</v-card-title>

      <past-sepa-payments-table byClient />
    </v-card>
  </div>
</template>
