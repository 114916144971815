<script>
export default {
  name: 'PastSepaPaymentsTable',
  props: {
    byClient: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paymentsHeaders: [
        { text: 'Data e ora', value: 'created' },
        { text: 'Cliente', value: 'clientCustomer', hide: !this.byClient },
        { text: 'Totale', value: 'total' },
        { text: 'Stato', value: 'status' },
        { text: 'Messaggio', value: 'message' },
      ].map(i => ({ ...i, sortable: false }))
          .filter(i => !i.hide),
      pastPayments: []
    }
  },
  mounted () {
    this.fetchPastPayments()
  },
  methods: {
    async fetchPastPayments () {
      const response = this.byClient
          ? await this.$http.payment.get('/sepa-payments/by-client/' + this.$store.getters.clientId)
          : await this.$http.payment.get('/sepa-payments')

      response.data.forEach(item => {
        item.created = item.created.join('-')
      })
      this.pastPayments = response.data
    },
    rowClick (item) {
      if (this.byClient) return

      this.$router.push({
        name: 'sepa-payments-details',
        params: {
          paymentId: item.id
        }
      })
    },
    getStatusColor (status) {
      switch (status) {
        case 'NOT_PAID':
        case 'WAITING_RESULT':
          return 'blue'
        case 'NOT_PAID_WAIT_SUPPORT':
          return 'red'
        case 'PAID':
          return 'green'
      }
    },
    getStatusIcon (status) {
      switch (status) {
        case 'NOT_PAID':
        case 'WAITING_RESULT':
          return 'fa-hourglass-half'
        case 'NOT_PAID_WAIT_SUPPORT':
          return 'fa-times'
        case 'PAID':
          return 'fa-check'
      }
    },
    getStatusMessage (item) {
      switch (item.status) {
        case 'NOT_PAID':
          return 'Il pagamento verrà inoltrato alla tua banca a breve.'
        case 'WAITING_RESULT':
          return 'Addebito inviato alla tua banca e in attesa di conferma.'
        case 'NOT_PAID_WAIT_SUPPORT':
          const supportEmail = `<a href="mailto:support@foodytek.com?subject=Pagamento SEPA non andato a buon fine&body=Buongiorno, non è andato a buon fine l'ultimo addebito SEPA (id: ${item.id}). Chiedo ulteriori informazioni.">
                                  support@foodytek.com
                                </a>`
          return `Il pagamento non è andato a buon fine, contattaci a ${supportEmail}.`
      }
    }
  },
}
</script>

<template>
  <v-data-table
      :headers="paymentsHeaders"
      :items="pastPayments"
      :class="{'row-pointer': !byClient}"
      @click:row="rowClick"
  >
    <template v-slot:item.clientCustomer="{ value }">
      {{ value.businessName }}
      <!--         todo: add link to client when create the details page -->
      <!--          <v-chip link outlined :to="`/users-wallet/${$store.getters.clientId}/transactions/${value.id}`">{{ value.email }}</v-chip>-->
    </template>
    <template v-slot:item.total="{ value }">
      {{ formatMoney(value) }}
    </template>
    <template v-slot:item.status="{ value }">
      <v-icon :color="getStatusColor(value)">{{ getStatusIcon(value) }}</v-icon>
    </template>
    <template v-slot:item.message="{ item }">
      <span v-html="getStatusMessage(item)"/>
    </template>
  </v-data-table>
</template>
