<template>
  <v-card outlined class="my-5">
    <v-card-title class="text-h5">
      Fasce di sconto
    </v-card-title>
    <v-card-text>
      <p>
        Di seguito puoi aggiungere e modificare le soglie del credito dell'utente che abilitano una determinata
        percentuale di sconto.
        (nella soglia deve essere usato il punto per i decimali)
      </p>
      <v-data-table
          style="max-width: 36rem"
          class="mx-auto"
          hide-default-footer
          :loading="thresholds === null"
          :items="thresholds || []"
          :headers="headers"
          item-key="threshold"
          sort-by="threshold"
          no-data-text="Nessuna scontistica applicata"
      >
        <template v-slot:item.threshold="{ item }">
          <price-text-field
              :value="item.threshold"
              @change="item.threshold = $event; valueUpdated"
              class="mt-3"
              type="number"
              dense
              :rules="rules.threshold"
          />
        </template>
        <template v-slot:item.discountPercentage="{ item }">
          <v-text-field
              v-model="item.discountPercentage"
              @change="valueUpdated"
              class="mt-3"
              type="number"
              dense
              :rules="rules.discountPercentage"
          />
        </template>
        <template v-slot:item.actions="{ index }">
          <v-btn
              color="red"
              class="pa-5"
              small
              icon
              @click.stop="removeThreshold(index)"
          >
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </template>

        <template v-slot:body.append>
          <tr>
            <td class="text-start">
              <price-text-field
                  v-model="newThreshold.threshold"
                  type="number"
                  class="mt-5 mb-n1"
                  dense outlined
                  :rules="rules.threshold"
              />
            </td>
            <td class="text-start">
              <v-text-field
                  v-model="newThreshold.discountPercentage"
                  type="number"
                  class="mt-5 mb-n1"
                  dense outlined
                  :rules="rules.discountPercentage"
              />
            </td>
            <td class="text-start">
              <v-btn color="primary" icon @click="addThreshold">
                <v-icon>fa-plus</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="text-center">
        <v-btn color="success" :disabled="!edited" @click="saveThresholds">
          Salva
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PriceTextField from '@/components/UIAccessories/PriceTextField'

export default {
  name: 'Discounts',
  components: { PriceTextField },
  props: {
    serialNumber: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      thresholds: null,
      edited: null,
      headers: [
        { value: 'threshold', text: 'Soglia', sortable: false },
        { value: 'discountPercentage', text: 'Percentuale di sconto', sortable: false },
        { value: 'actions', text: '', sortable: false }
      ],
      newThreshold: {
        threshold: 0,
        discountPercentage: null
      },
      rules: {
        threshold: [v => v >= 0 || 'Il valore deve essere maggiore o uguale a 0'],
        discountPercentage: [v => (v > 0 && v < 100) || 'Il valore deve essere compreso tra 0 e 100']
      }
    }
  },
  mounted () {
    this.loadDiscountThresholds()
  },

  methods: {
    async loadDiscountThresholds () {
      const res = await this.$http.payment.get(`foodytek-pk/${this.serialNumber}/discount-thresholds`)
      this.thresholds = res.data
    },
    addThreshold () {
      if (this.newThreshold.threshold === null || this.newThreshold.discountPercentage === null) {
        return
      }
      this.thresholds.push(this.newThreshold)
      this.newThreshold = {
        threshold: null,
        discountPercentage: null
      }
      this.valueUpdated()
    },
    removeThreshold (index) {
      this.thresholds.splice(index, 1)
      this.valueUpdated()
    },
    valueUpdated () {
      this.edited = true
    },
    async saveThresholds () {
      await this.$http.payment.put(`foodytek-pk/${this.serialNumber}/discount-thresholds`, this.thresholds)
      this.edited = false
      this.$dialog.notify.success('Fasce salvate con successo').then()
    }
  }
}
</script>

<style scoped>
</style>
