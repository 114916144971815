<script>
import RemoteTopUpListTable from "@/views/client_customers/history/RemoteTopUpsTable.vue";
import PastSepaPaymentsTable from "@/components/PastSepaPaymentsTable.vue";

export default {
  name: "PastTopUps",
  components: { PastSepaPaymentsTable, RemoteTopUpListTable },
  data () {
    return {
      monthTopUps: [],
    }
  },
  mounted () {
    this.fetchTopUps()
  },
  methods: {
    async fetchTopUps () {
      const response = await this.$http.payment.get('/remote-top-ups')
      this.monthTopUps = response.data
    },
  },
}
</script>

<template>
  <div>
    <h1>Storico ricariche remote Wallet</h1>

    <v-card class="my-6" outlined>
      <v-card-title>Ricariche del mese corrente (saranno addebitate i primi giorni del mese successivo)</v-card-title>

      <v-card-text>
        Totale addebiti del mese ad oggi: {{ formatMoney(monthTopUps.reduce((acc, item) => acc + item.totalAmount, 0)) }}
      </v-card-text>

      <remote-top-up-list-table :topUps="monthTopUps"/>
    </v-card>

    <v-card class="my-6" outlined>
      <v-card-title>Addebiti SEPA passati</v-card-title>

      <past-sepa-payments-table />
    </v-card>
  </div>
</template>
