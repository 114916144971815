<template>
  <div>
    <div class="text-center">
      <v-btn
          color="primary"
          class="mb-3"
          @click="getDeviceTopUpAnomalies"
      >
        Load anomalies
      </v-btn>
    </div>

    <v-data-table
        v-if="devices !== null"
        :items="devices"
        :headers="headers"
        sort-by="id.fromInstant"
        sort-desc
        item-key="index"
    >
      <template v-slot:item.id.fromInstant="{ value }">
        {{ formatDateTime(value) }}
      </template>
      <template v-slot:item.id.toInstant="{ value }">
        {{ formatDateTime(value) }}
      </template>
      <template v-slot:item.amount="{ value }">
        {{ formatMoney(value) }}
      </template>
      <template v-slot:item.user="{ value }">
        <v-tooltip bottom v-if="value">
          <template v-slot:activator="{ on }">
            <v-chip link outlined v-on="on" @click="copyTextToClipboard(value.id)">{{ value.email }}</v-chip>
          </template>
          <span>
            <b>{{value.name}} {{value.surname}}</b>
            <br>
            Click to copy id:
            <br>
            {{ value.id }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.lastUser="{ value }">
        <v-tooltip bottom v-if="value">
          <template v-slot:activator="{ on }">
            <v-chip link outlined v-on="on" @click="copyTextToClipboard(value.id)">{{ value.email }}</v-chip>
          </template>
          <span>
            <b>{{value.name}} {{value.surname}}</b>
            <br>
            Click to copy id:
            <br>
            {{ value.id }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'DeviceAnomalies',
  props: {
    serialNumber: {
      type: String,
      required: true
    },
    fromDate: {
      type: Object,
      required: true
    },
    toDate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      devices: null,
      headers: [
        { value: 'id.fromInstant', text: 'Date from' },
        { value: 'id.toInstant', text: 'Date to' },
        { value: 'amount', text: 'Amount' },
        { value: 'user', text: 'User' },
        { value: 'lastUser', text: 'Previous user' }
      ].map(i => {
        i.sortable = false
        return i
      })
    }
  },
  mounted () {
    this.getDeviceTopUpAnomalies()
  },
  methods: {
    getDateFromObj (d) {
      const [year, month, day] = d.date.split('-')
      const [hour, min] = d.time.split(':')

      const date = new Date()
      date.setFullYear(year, month - 1, day)
      date.setHours(hour, min)
      return date.toISOString()
    },
    async getDeviceTopUpAnomalies () {
      try {
        const fromDate = this.getDateFromObj(this.fromDate)
        const toDate = this.getDateFromObj(this.toDate)
        const response = await this.$http.device.get(`devices/${this.serialNumber}/anomalies/top-up/${fromDate}/${toDate}`)
        this.devices = response.data
        this.devices.forEach((item, i) => {
          item.index = i
        })
      } catch (err) {
      }
    }
  }
}
</script>

<style scoped>

</style>
