<template>
  <action-modal
      v-model="fieldValue"
      :data="partner"
      :edit-mode="editMode"
      :submit-btn-text="$t('commons.create')"
      :edit-btn-text="$t('commons.edit')"
      :cancel-btn-text="$t('commons.cancel')"
      :loading-text="$t('commons.loading')"

      :submit-title="$t('partners.modal.createTitle')"
      :edit-title="$t('partners.modal.editTitle')"
      :submit-success-message="$t('partners.messages.created')"
      :edit-success-message="$t('partners.messages.edited')"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createCallback"
      :edit-promise-callback="editCallback"
      @close="close"
  />
</template>

<script>

import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'PartnerModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean
    },
    partner: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        md: 6
      }
    }
  },
  computed: {
    fields () {
      return [
        { value: 'name', label: this.$t('commons.data.name'), rules: 'required' },
        { value: 'businessName', label: this.$t('commons.data.businessName'), rules: '' },
        { value: 'phoneNumber', label: this.$t('commons.data.phoneNumber'), rules: '' },
        { value: 'city', label: this.$t('commons.data.city'), rules: '' },
        { value: 'address', label: this.$t('commons.data.address'), rules: '' },
        { value: 'civicNumber', label: this.$t('commons.data.civicNumber'), rules: '' }
      ]
    },
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createCallback () {
      return this.$http.device.post(
        '/partners',
        this.partner
      )
    },
    editCallback () {
      return this.$http.device.put(
        '/partners/' + this.partner.id,
        this.partner
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
