<template src="./AdminsManagement.html"/>

<script>
import RegistrationLinkModal from '@/components/modals/commons/RegistrationLinkModal.vue'

export default {
  name: 'AdminsManagement',
  components: { RegistrationLinkModal },
  data () {
    return {
      users: [],
      tokens: [],
      search: '',
      registrationModal: false,

      tokenHeaders: [
        { text: 'Email', value: 'email' },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('commons.data.name'), value: 'name' },
        { text: this.$t('commons.data.surname'), value: 'surname' },
        { text: 'Email', value: 'email' },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getRegistrationTokens()
      this.getUsers()
    },
    reload () {
      setTimeout(this.init, 200)
    },
    async getUsers () {
      try {
        const response = await this.$http.auth.get('/queries/users/role/ft-client-admins')
        this.users = response.data
      } catch (e) {
      }
    },
    async getRegistrationTokens () {
      try {
        const response = await this.$http.auth.get('queries/registration-tokens/ft-client/admins')
        this.tokens = response.data
      } catch (e) {
      }
    },

    async removeUser (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('usersManagement.admins.removeMessage', { email: item.email }), this.$t('usersManagement.remove'))
        await this.$http.auth.delete('commands/users/downgrade/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
    async revokeToken (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('usersManagement.revokeTokenMessage', { email: item.email }), this.$t('usersManagement.revoke'))
        await this.$http.auth.delete('commands/registration-tokens/' + item.id)
        this.reload()
      } catch (e) {
      }
    }
  }
}
</script>
