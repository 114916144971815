<template>
  <div>
    <div class="text-h4">
      <v-btn icon @click="$router.back()">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <span class="ms-3">
        Sedi in <span class="font-weight-bold">{{ customerName }}</span>
      </span>
    </div>

    <div class="d-flex align-center ma-5">
      <v-text-field
          v-model="search"
          append-icon="fa-search"
          :label="$t('commons.search')"
          single-line
          outlined
          hide-details
      />
      <v-btn
          class="ml-6"
          color="green"
          dark
          v-text="$t('commons.create')"
          @click="createModal"
      />
    </div>

    <v-card outlined>
      <v-data-table
          class="row-pointer"
          :items="locations"
          :headers="headers"
          :search="search"
          item-key="item.id"
          :no-data-text="$t('locations.noData')"
          @click:row="handleRowClick"
      >
        <template v-slot:item.address="{ item }">
          {{ item.address ? item.address + " " + item.civicNumber : '' }}
        </template>
        <template v-slot:item.crud="{ item }">
          <v-btn
              color="warning"
              class="pa-5"
              small
              icon
              @click.stop="editModal(item)">
            <v-icon small>fa-edit</v-icon>
          </v-btn>
          <v-btn color="red"
                 class="pa-5"
                 small icon
                 @click.stop="openDeleteLocationModal(item)">
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <LocationModal
        v-model="actionModal"
        :edit-mode="actionModalEditMode"
        :location="modalObject"
        :partner-id="partnerId || ''"
        @close="closeActionModal"/>
  </div>
</template>

<script>
import LocationModal from '../../../components/modals/business/LocationModal.vue'

export default {
  name: 'LocationsList',
  components: { LocationModal },
  data () {
    return {
      partnerId: this.$route.params.partnerId,
      customerName: null,
      locations: [],
      search: '',

      confirmModal: false,
      actionModal: false,
      actionModalEditMode: false,
      modalObject: {
        id: null,
        address: null,
        city: null,
        civicNumber: null,
        name: null
      }
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('commons.data.name'), value: 'name' },
        { text: this.$t('commons.data.city'), value: 'city' },
        { text: this.$t('commons.data.address'), value: 'address' },
        { text: '', value: 'crud' }
      ]
    }
  },
  mounted () {
    this.getLocations()
  },
  methods: {
    async getLocations () {
      try {
        const response = await this.$http.device.get('/locations/' + this.partnerId)
        this.customerName = response.data.name
        this.locations = response.data.locations
      } catch (e) {
      }
    },
    reload () {
      setTimeout(this.getLocations, 200)
    },
    async openDeleteLocationModal (location) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('locations.messages.confirmDeletion').toString() + ' <b>"' + location.name + '"</b>')
        await this.deleteLocation(location.id)
      } catch (e) {
      }
    },
    async deleteLocation (id) {
      try {
        await this.$http.device.delete('/locations/' + id)
        this.confirmModal = false
        await this.$dialog.notify.success(this.$t('locations.messages.deleted').toString())
        this.reload()
      } catch (e) {
      }
    },
    createModal () {
      this.actionModalEditMode = false
      this.modalObject = {}
      this.actionModal = true
    },
    editModal (location) {
      this.actionModalEditMode = true
      this.modalObject = Object.assign({}, location)
      this.actionModal = true
    },
    closeActionModal () {
      this.actionModal = false
      this.reload()
    },
    handleRowClick (location) {
      this.$router.push(`/locations/${location.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
