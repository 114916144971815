<template>
  <action-modal
      v-model="fieldValue"
      :data="data"
      :edit-mode="editMode"
      :submit-btn-text="$t('commons.create')"
      :edit-btn-text="$t('commons.edit')"
      :cancel-btn-text="$t('commons.cancel')"
      :loading-text="$t('commons.loading')"

      :submit-title="$t('userGroups.modal.createTitle')"
      :edit-title="$t('userGroups.modal.editTitle')"
      :submit-success-message="$t('userGroups.messages.created')"
      :edit-success-message="$t('userGroups.messages.edited')"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createLocationCallback"
      :edit-promise-callback="editLocationCallback"
      @close="close"
  />
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'UserGroupModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean
    },
    data: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        // md: 6
      }
    }
  },
  computed: {
    fields () {
      return [
        { value: 'name', label: this.$t('commons.data.name'), rules: 'required' },
        { value: 'notes', label: this.$t('userGroups.fields.notes') + this.$t('commons.optionalField'), rules: '' },
      ]
    },
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    createLocationCallback () {
      return this.$http.auth.post(
        '/user-groups',
        this.data
      )
    },
    editLocationCallback () {
      return this.$http.auth.put(
        '/user-groups/' + this.data.id,
        this.data
      )
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
