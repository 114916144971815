<template>
  <v-card outlined class="my-5">
    <v-card-title class="text-h5">
      Esportazione dati in Eva-Dts
    </v-card-title>
    <v-card-text>
      <p>
        Qui puoi esportare i dati del dispositivo in formato Eva-Dts. Seleziona il periodo di tempo per cui vuoi
        esportare i dati.
      </p>

      <v-row class="mx-3">
        <v-col>
          <ft-month-select
              label="Dal mese"
              @month-selected="startMonthSelected"
              previous-month-default
          />
        </v-col>
        <v-col>
          <ft-month-select
              label="Al mese (incluso)"
              @month-selected="endMonthSelected"
              previous-month-default
          />
        </v-col>
      </v-row>

      <div class="text-center">
        <v-btn @click="download" color="primary" :disabled="loading" :loading="loading" outlined>Scarica</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import FtMonthSelect from '@/components/UIAccessories/FtMonthSelect'

export default {
  name: 'EvaDts',
  components: { FtMonthSelect },
  props: {
    serialNumber: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      startDate: null, // this object contains year and month fields
      endDate: null,
      loading: false
    }
  },
  methods: {
    startMonthSelected (month) {
      this.startDate = month
    },
    endMonthSelected (month) {
      this.endDate = month
    },
    async download () {
      this.loading = true

      // date months start from 0 but saved dates start from 1
      const from = new Date(this.startDate.year, this.startDate.month - 1)
      const to = new Date(this.endDate.year, this.endDate.month) // get the first day of the next month
      to.setMilliseconds(-1) // set the last millisecond of the previous month

      try {
        const res = await this.$http.api.get(`reports/v1/eva-dts/${this.serialNumber}?from=${from.toISOString()}&to=${to.toISOString()}`)

        const filename = `EvaDts_${this.serialNumber}_${this.startDate.year}${this.startDate.month}-${this.endDate.year}${this.endDate.month}.txt`
        this.createFileAndDownload(res.data.evadts, 'text/plain', filename)
      } catch (ignored) {
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
