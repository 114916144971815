<!--<template src="./Login.html" />-->
<template>
<!--  <v-container class="mt-5" v-if="$env.isLocalhost">-->
<!--    <v-text-field-->
<!--        label="To login redirect to"-->
<!--        v-model="url"-->
<!--        outlined-->
<!--    />-->
<!--    <v-btn color="success" @click="localhostRedirect">-->
<!--      Redirect-->
<!--    </v-btn>-->
<!--  </v-container>-->
  <div></div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      url: 'http://localhost:8080'
    }
  },
  mounted () {
    const token = this.$route.query.token
    if (!token) {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push('/')
      } else if (this.$env.isLocalhost) {
        window.location = 'http://localhost:8080'
      } else {
        window.location = `https://login.${this.$env.domainPostfix}`
      }
      return
    }

    const redirectTo = this.$route.query.to
    this.$store.dispatch('renewToken', { token, redirectTo })
  },
  methods: {
    localhostRedirect () {
      window.location = this.url
    }
  }
}
</script>
