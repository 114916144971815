<script>
export default {
  name: 'DayOrMonthSelector',
  props: {
    value: String,
    fetchData: {
      type: Function, // this function must return a promise!
      required: true
    }
  },
  data () {
    return {
      periodType: 'month',
      periodTypes: [
        { text: 'Giorno', value: 'day' },
        { text: 'Mese', value: 'month' }
      ],
      selectedDate: new Date().toISOString().slice(0, 7), // Set current month as default
      menu: false,
      loading: true,
    }
  },
  computed: {
    date: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    if (!this.value) {
      this.date = new Date().toISOString().slice(0, 7) // Set current month as default
    } else {
      this.reload()
    }
  },
  methods: {
    reload () {
      this.loading = true
      const promise = this.fetchData(this.periodType, this.date)

      if (!promise instanceof Promise) {
        if (this.$env.isLocalhost) {
          console.error('In component DayOrMonthSelector, the fetchData prop must return a promise!')
        }
        return
      }

      promise.then(() => {
        this.loading = false
      })
    },
    isValidDate (date, type) {
      if (type === 'day') {
        const parsedDate = new Date(date);
        return !isNaN(parsedDate.getTime());
      } else if (type === 'month') {
        const [ year, month ] = date.split('-').map(Number);
        return (
            !isNaN(year) && year > 0 &&
            !isNaN(month) && month >= 1 && month <= 12
        );
      }
      return false;
    }
  },
  watch: {
    date (newDate) {
      if (newDate && this.isValidDate(newDate, this.periodType)) {
        this.reload();
      }
    },
  },
}
</script>

<template>
  <div>
    <!-- Period Selector -->
    <div class="mt-3 body-1">Puoi visualizzare i dati di un giorno o di un mese selezionando il periodo desiderato qui sotto.</div>
    <br>
    <div class="d-inline-flex" style="width: 100%">
      <v-btn-toggle v-model="periodType" mandatory>
        <v-btn v-for="item in periodTypes" :key="item.value" :value="item.value">
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="date"
              :loading="loading"
              label="Seleziona mese o data"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-if="periodType === 'day'"
            v-model="date"
            @input="menu = false"
        ></v-date-picker>
        <v-date-picker
            v-else
            v-model="date"
            @input="menu = false"
            type="month"
            :picker-date.sync="date"
        ></v-date-picker>
      </v-menu>

      <slot name="default"/>
    </div>
  </div>
</template>

<style scoped>

</style>