var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":_vm.getDeviceTopUpAnomalies}},[_vm._v(" Load anomalies ")])],1),(_vm.devices !== null)?_c('v-data-table',{attrs:{"items":_vm.devices,"headers":_vm.headers,"sort-by":"id.fromInstant","sort-desc":"","item-key":"index"},scopedSlots:_vm._u([{key:"item.id.fromInstant",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.id.toInstant",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.user",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value.id)}}},on),[_vm._v(_vm._s(value.email))])]}}],null,true)},[_c('span',[_c('b',[_vm._v(_vm._s(value.name)+" "+_vm._s(value.surname))]),_c('br'),_vm._v(" Click to copy id: "),_c('br'),_vm._v(" "+_vm._s(value.id)+" ")])]):_vm._e()]}},{key:"item.lastUser",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value.id)}}},on),[_vm._v(_vm._s(value.email))])]}}],null,true)},[_c('span',[_c('b',[_vm._v(_vm._s(value.name)+" "+_vm._s(value.surname))]),_c('br'),_vm._v(" Click to copy id: "),_c('br'),_vm._v(" "+_vm._s(value.id)+" ")])]):_vm._e()]}}],null,false,223342776)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }