var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":_vm.loadData}},[_vm._v(" Load purchases ")])],1),(_vm.items !== null)?_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"item-key":"purchaseId","sort-by":"purchaseId"},scopedSlots:_vm._u([{key:"item.requestReceived",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatDateTime(value) : '-')+" ")]}},{key:"item.resultReceived",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatDateTime(value) : '-')+" ")]}},{key:"item.resultReceivedFromStatus",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDateTime(value))),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.resultReceivedFromStatusCount)+" times")])]):_c('div',[_vm._v("-")])]}},{key:"item.tooFastDiscard",fn:function(ref){
var value = ref.value;
return [(value)?_c('boolean-icon',{attrs:{"value":!value}}):_vm._e()]}},{key:"item.charged",fn:function(ref){
var value = ref.value;
return [_c('boolean-icon',{attrs:{"value":value}})]}},{key:"item.vendCompleted",fn:function(ref){
var value = ref.value;
return [(value !== null)?_c('boolean-icon',{attrs:{"value":value}}):_c('span',{staticClass:"font-italic text-caption"},[_vm._v("No result received")])]}},{key:"item.shouldBeRefundedAutomatically",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("fa-circle-exclamation")]):_vm._e()]}},{key:"item.previousStatus",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.formatDateTime(value.instant)))]),_c('br'),(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.formatDuration(value.timestamp)))])]}}],null,true)},[_c('span',[_vm._v(" Duration from last boot "),_c('br'),_vm._v(" "+_vm._s(_vm.formatDateTime(value.instant - value.timestamp / 1000000))+" ")])]):_vm._e()],1):_vm._e()]}},{key:"item.nextStatus",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.formatDateTime(value.instant)))]),_c('br'),(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.formatDuration(value.timestamp)))])]}}],null,true)},[_c('span',[_vm._v(" Duration from last boot "),_c('br'),_vm._v(" "+_vm._s(_vm.formatDateTime(value.instant - value.timestamp / 1000000))+" ")])]):_vm._e()],1):_vm._e()]}},{key:"item.refunded",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('div',[_vm._v(" Yes: "+_vm._s(_vm.getReasonCode(item.refundReason))+" ("+_vm._s(item.refundReason)+") ")]):(item.charged)?_c('div',[_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.refundTransaction(item)}}},[_vm._v(" Rimborsa ")])],1):_vm._e()]}},{key:"item.transactionId",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"small":"","link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value)}}},on),[_vm._v("Copy ID")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value)+" ")])]):_vm._e()]}}],null,false,909581426)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }