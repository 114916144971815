import Vue from 'vue'
import '@fortawesome/fontawesome-free/css/all.css'

// import it from 'vuetify/lib/locale/it'

import Vuetify, {
  VRow, VCol, VCard, VContainer, VToolbar, VToolbarTitle, VCardText, VForm, VTextField, VCardActions,
  VSpacer, VBtn
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VRow,
    VCard,
    VCol,
    VContainer,
    VToolbar,
    VToolbarTitle,
    VCardText,
    VForm,
    VTextField,
    VCardActions,
    VSpacer,
    VBtn
  }
})

// import Vuetify from "vuetify";
// Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#213C67',
        secondary: '#FA481C',
        grey: '#8D95A2',
        'secondary-light': '#feece8',
        opposite: '#ffc600',
        lighter: '#156ec5', // un colore più chiaro abbinabile al primario, per i pulsanti, ad esempio
      },
      dark: {
      }
    }
  },
  // lang: {
  //   locales: { it },
  //   current: 'it'
  // }
})
