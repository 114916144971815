var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"foodytekClients"}},[(_vm.registrationTokens && _vm.registrationTokens.length)?_c('v-card',{staticClass:"mt-8 elevation-8 mx-auto mb-7",staticStyle:{"width":"90%"}},[_c('v-card-title',{staticClass:"d-block text-center pt-6 pb-5 text-h4"},[_vm._v(" "+_vm._s(_vm.$t('user.registrationTokens'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.registrationTokens,"headers":_vm.registrationTokensTableFields,"item-key":"item.id"},scopedSlots:_vm._u([{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","fab":"","icon":"","color":"red"},on:{"click":function($event){return _vm.revokeToken(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)]}}],null,false,2476369523)})],1)],1):_vm._e(),(_vm.pendingUsers && _vm.pendingUsers.length)?_c('v-card',{staticClass:"mt-8 elevation-8 mx-auto mb-7",staticStyle:{"width":"90%"}},[_c('v-card-title',{staticClass:"d-block text-center pt-6 pb-5 text-h4"},[_vm._v(" "+_vm._s(_vm.$t('foodytekClients.pendingSupplierRegistrations'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.pendingUsers,"headers":_vm.pendingRegistrationTableFields,"item-key":"item.id"},scopedSlots:_vm._u([{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","fab":"","icon":"","color":"red"},on:{"click":function($event){return _vm.downgradeUser(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-angle-double-down")])],1)]}}],null,false,2270792900)})],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-8 elevation-8 mx-auto mb-7",staticStyle:{"width":"90%"}},[_c('v-card-title',{staticClass:"d-block text-center pt-6 pb-5 text-h4"},[_vm._v(" "+_vm._s(_vm.$t('foodytekClients.title'))+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center mb-4 mx-5"},[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":_vm.$t('commons.search'),"single-line":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-6",attrs:{"color":"green","dark":""},domProps:{"textContent":_vm._s(_vm.$t('commons.create'))},on:{"click":function($event){_vm.registrationLinkModal = true}}})],1),_c('v-data-table',{attrs:{"items":_vm.ftClients,"headers":_vm.tableFields,"search":_vm.search,"item-key":"item.id"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.businessName",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-chip',{attrs:{"link":"","outlined":"","to":("/ft-clients/" + (item.id))}},[_vm._v(_vm._s(value))])]}},{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"warning","fab":"","icon":"","disabled":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1),_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","fab":"","icon":"","color":"red"},on:{"click":function($event){return _vm.deleteSupplierAskModal(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)]}}])})],1)],1),_c('registration-link-modal',{attrs:{"user-role":"foodytek-client-admin"},on:{"close":_vm.reload},model:{value:(_vm.registrationLinkModal),callback:function ($$v) {_vm.registrationLinkModal=$$v},expression:"registrationLinkModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }