<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" v-for="(date, i) in dates" v-bind:key="i">
        <v-row>
          <v-col cols="12" sm="6">
            <v-menu
                :ref="'menu' + i"
                v-model="date.menu"
                transition="slide-y-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date.date"
                    :label="!i ? 'From date (Es: 2022-04-01)' : 'To date (Es: 2022-04-02)'"
                    prepend-icon="fa-solid fa-calendar-day"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date.date"
                  no-title
                  scrollable
                  @change="$refs['menu' + i][0].save(date.time)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
                :ref="'timeMenu' + i"
                v-model="date.timeMenu"
                transition="slide-y-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date.time"
                    :label="!i ? 'From time (Es: 11:20)' : 'To time (Es: 12:00)'"
                    prepend-icon="fa solid fa-clock"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  format="24hr"
                  v-model="date.time"
                  scrollable
                  @click:minute="$refs['timeMenu' + i][0].save(date.time)"
              >
              </v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-tabs
        v-model="tab"
        background-color="accent-4"
        class="my-5"
        center-active
        align-with-title
        grow
    >
      <v-tab>Events</v-tab>
      <v-tab>Anomalies</v-tab>
      <v-tab>Purchases</v-tab>
    </v-tabs>

<!--    <v-window-item v-model="tab">-->
<!--      <v-window-item>-->
<!--        <events :serial-number="serialNumber" :from-date="dates[0]" :to-date="dates[1]"/>-->
<!--      </v-window-item>-->
<!--      <v-window-item>-->
<!--        <anomalies :serial-number="serialNumber" :from-date="dates[0]" :to-date="dates[1]"/>-->
<!--      </v-window-item>-->
<!--      <v-window-item>-->
<!--        <purchases :serial-number="serialNumber" :from-date="dates[0]" :to-date="dates[1]"/>-->
<!--      </v-window-item>-->
<!--    </v-window-item>-->

    <v-tabs-items touchless v-model="tab">
      <v-tab-item>
        <events :serial-number="serialNumber" :from-date="dates[0]" :to-date="dates[1]"/>
      </v-tab-item>
      <v-tab-item>
        <anomalies :serial-number="serialNumber" :from-date="dates[0]" :to-date="dates[1]"/>
      </v-tab-item>
      <v-tab-item>
        <purchases :serial-number="serialNumber" :from-date="dates[0]" :to-date="dates[1]"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Events from "@/views/commons/DeviceDetails/tabs/monitoring/Events.vue";
import Anomalies from "@/views/commons/DeviceDetails/tabs/monitoring/Anomalies.vue";
import Purchases from "@/views/commons/DeviceDetails/tabs/monitoring/Purchases.vue";

export default {
  name: 'Monitoring',
  components: { Purchases, Anomalies, Events },
  props: {
    serialNumber: {
      type: String,
      required: true
    }
  },
  data () {
    const to = new Date()
    const from = new Date()
    from.setDate(from.getDate() - 7)

    return {
      tab: 0,
      dates: [
        {
          date: from.toISOString().substring(0, 10),
          time: from.toTimeString().substring(0, 5),
          menu: false,
          timeMenu: false
        },
        {
          date: to.toISOString().substring(0, 10),
          time: to.toTimeString().substring(0, 5)
        }
      ],
      headers: [
        { value: 'id.fromInstant', text: 'Date from' },
        { value: 'id.toInstant', text: 'Date to' },
        { value: 'amount', text: 'Amount' },
        { value: 'user', text: 'User' },
        { value: 'lastUser', text: 'Previous user' }
      ].map(i => {
        i.sortable = false
        return i
      })
    }
  },
  mounted () {
    this.getDeviceTopUpAnomalies()
  },
  methods: {
    getDateFromObj (d) {
      const [year, month, day] = d.date.split('-')
      const [hour, min] = d.time.split(':')

      const date = new Date()
      date.setFullYear(year, month - 1, day)
      date.setHours(hour, min)
      return date.toISOString()
    },
    async getDeviceTopUpAnomalies () {
      try {
        const fromDate = this.getDateFromObj(this.dates[0])
        const toDate = this.getDateFromObj(this.dates[1])
        const response = await this.$http.device.get(`devices/${this.serialNumber}/anomalies/top-up/${fromDate}/${toDate}`)
        this.devices = response.data
        this.devices.forEach((item, i) => {
          item.index = i
        })
      } catch (err) {
      }
    }
  }
}
</script>

<style scoped>

</style>
