var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('action-modal',{attrs:{"data":_vm.obj,"cancel-btn-text":"Annulla","loading-text":"Ricarica...","submit-title":("Fai una ricarica per " + (_vm.user.email)),"submit-success-message":"Chiavetta ricaricata con successo!","submit-btn-text":"Ricarica","submit-promise-callback":_vm.topUpVK,"fields":_vm.fields},scopedSlots:_vm._u([{key:"field.amount",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('price-input',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors},model:{value:(_vm.obj.amount),callback:function ($$v) {_vm.$set(_vm.obj, "amount", $$v)},expression:"obj.amount"}})]}},{key:"field.reason",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors,"items":_vm.reasonTypes},model:{value:(_vm.obj.reason),callback:function ($$v) {_vm.$set(_vm.obj, "reason", $$v)},expression:"obj.reason"}})]}},{key:"field.giveAutoGift",fn:function(){return [_c('v-checkbox',{staticClass:"mt-n4",attrs:{"disabled":_vm.giveGiftCheckboxDisabled,"label":("Ricarica anche " + (_vm.formatMoney(_vm.giftAmount)) + " come regalo")},model:{value:(_vm.obj.giveAutoGift),callback:function ($$v) {_vm.$set(_vm.obj, "giveAutoGift", $$v)},expression:"obj.giveAutoGift"}})]},proxy:true}],null,false,2278103472),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }