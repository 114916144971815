var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-3"},[_vm._v("Corrispettivi inviati all'Agenzia delle Entrate")]),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.corrispettivi,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"item-key":"to"},scopedSlots:_vm._u([{key:"item.serialNumber",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"link":"","outlined":"","to":("/devices/" + value)}},[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.downloadXml(item)}}},'v-btn',attrs,false),on),[_vm._v(" XML ")])]}}],null,true)},[_c('span',[_vm._v("Scarica file XML inviato")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }