<script>
import AmountProgressCircular from "@/views/business/statistics/AmountProgressCircular.vue";
import DayOrMonthSelector from "@/views/business/statistics/DayOrMonthSelector.vue";
import ExcludeFromStatsDialog from "@/views/business/statistics/ExcludeFromStatsDialog.vue";

export default {
  name: 'Dashboard',
  components: { ExcludeFromStatsDialog, DayOrMonthSelector, AmountProgressCircular },
  data () {
    return {
      selectedDate: null,
      amounts: {
        purchases: 0,
        legacy: 0,
        cashTopUps: 0,
        cardTopUps: 0,
        dashboardTopUps: 0,
        dashboardGiftTopUps: 0
      },
    };
  },
  computed: {
    totalTopUps () {
      return this.amounts.cardTopUps + this.amounts.cashTopUps + this.amounts.dashboardTopUps + this.amounts.dashboardGiftTopUps;
    },
    totalPurchases () {
      return this.amounts.purchases + this.amounts.legacy;
    },
  },
  methods: {
    async fetchData (periodType) {
      try {
        const res = await this.$http.reports.get('v2/dashboard', {
          params: {
            [ periodType === 'day' ? 'date' : 'month' ]: this.selectedDate,
          }
        })
        this.amounts = res.data;
      } catch (e) {
      }
    },
  },
};
</script>

<template>
  <v-container>
    <h1>Dashboard</h1>
    <!-- Period Selector -->
    <day-or-month-selector v-model="selectedDate" :fetch-data="fetchData">
      <v-spacer/>

      <exclude-from-stats-dialog />
    </day-or-month-selector>

    <!-- Purchases -->
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="pa-3" outlined>
          <v-card-title class="headline">
            Totale acquisti:
            {{ formatMoney(totalPurchases) }}
          </v-card-title>
          <v-card-text>
            <v-row class="my-1">
              <v-col cols="12" md="6">
                <amount-progress-circular
                    text="Con app Apollo"
                    color="primary"
                    :amount="amounts.purchases"
                    :total="totalPurchases"
                />
              </v-col>
              <v-col cols="12" md="6">
                <amount-progress-circular
                    text="Senza app Apollo"
                    color="secondary"
                    :amount="amounts.legacy"
                    :total="totalPurchases"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Header -->
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3" outlined>
          <v-card-title class="headline">
            Totale caricato su Apollo: {{ formatMoney(totalTopUps) }}
          </v-card-title>
          <v-card-text>
            <v-row class="my-1">
              <v-col cols="12" md="4">
                <amount-progress-circular
                    text="Carta di credito"
                    color="primary"
                    :amount="amounts.cardTopUps"
                    :total="totalTopUps"
                />
              </v-col>
              <v-col cols="12" md="4">
                <amount-progress-circular
                    text="Cash"
                    color="secondary"
                    :amount="amounts.cashTopUps + amounts.dashboardTopUps"
                    :total="totalTopUps"
                />
              </v-col>
              <v-col cols="12" md="4">
                <amount-progress-circular
                    text="Regalo"
                    color="success"
                    :amount="amounts.dashboardGiftTopUps"
                    :total="totalTopUps"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.v-card {
  text-align: center;
}
</style>