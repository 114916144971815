<script>
import RemoteTopUpListTable from "@/views/client_customers/history/RemoteTopUpsTable.vue";

export default {
  name: "RemoteTopUpListInPayment",
  components: { RemoteTopUpListTable },
  data () {
    return {
      payment: null,
      topUps: [],
    }
  },
  mounted () {
    this.fetchTopUps()
  },
  methods: {
    async fetchTopUps () {
      const response = await this.$http.payment.get(`/sepa-payments/${this.$route.params.paymentId}/remote-top-ups`)
      this.payment = response.data
      this.topUps = this.payment.remoteTopUps
    },
  },
}
</script>

<template>
  <div v-if="payment">
    <h1>Ricariche del pagamento {{ payment.created.join('-') }}</h1>
<!--   TODO: display other information -->
    <remote-top-up-list-table :topUps="topUps"/>
  </div>
</template>
