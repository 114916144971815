var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDeviceEvents}},[_vm._v(" Load events ")])],1),(_vm.devices !== null)?_c('v-row',{staticClass:"mx-auto mt-3",staticStyle:{"max-width":"50rem"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.types,"label":"Types","multiple":"","small-chips":"","chips":""},model:{value:(_vm.filters.types),callback:function ($$v) {_vm.$set(_vm.filters, "types", $$v)},expression:"filters.types"}})],1)],1):_vm._e(),(_vm.devices !== null)?_c('v-data-table',{attrs:{"items":_vm.devicesFiltered,"headers":_vm.headers,"item-key":"index"},scopedSlots:_vm._u([{key:"item.instant",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(!['CONNECT', 'DISCONNECT'].includes(item.type) ? _vm.formatMoney(value) : '')+" ")]}},{key:"item.counter",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatMoney(value) : '')+" ")]}},{key:"item.user",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value.id)}}},on),[_vm._v(_vm._s(value.email))])]}}],null,true)},[_c('span',[_c('b',[_vm._v(_vm._s(value.name)+" "+_vm._s(value.surname))]),_c('br'),_vm._v(" Click to copy id: "),_c('br'),_vm._v(" "+_vm._s(value.id)+" ")])]):_vm._e()]}}],null,false,1169536581)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }