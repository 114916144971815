<script>
import TabLayout from "@/components/UIAccessories/TabLayout.vue";
import GroupFeatureForDeviceModal from "@/components/modals/business/GroupFeatureForDeviceModal.vue";

export default {
  name: "UserGroupFeatures",
  components: { GroupFeatureForDeviceModal, TabLayout },
  props: {
    serialNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Tipo funzione",
          value: "type"
        },
        {
          text: "Descrizione",
          value: "description"
        },
        {
          text: "Percentuale sconto",
          value: "discount"
        },
        {
          text: "Gruppo utenti",
          value: "userGroup"
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false
        }
      ],
      featureTypes: {
        ACCESS: 'Accesso/utilizzo',
        DISCOUNT: 'Sconto',
    },
      userGroupFeatures: [],
      modal: {
        show: false,
        data: {},
        editMode: false
      }
    };
  },
  mounted () {
    this.loadUserGroupFeatures()
  },
  methods: {
    async loadUserGroupFeatures () {
      const res = await this.$http.device.get(`devices/${this.serialNumber}/user-groups-features`)
      this.userGroupFeatures = res.data
    },
    addFeature () {
      this.modal.editMode = false
      this.modal.data = {}
      this.modal.show = true
    },
    editFeature (item) {
      this.modal.editMode = true
      this.modal.data = Object.assign({}, item)
      this.modal.data.userGroupId = item.userGroup.id
      this.modal.show = true
    },
    async removeFeature (item) {
      await this.$http.device.delete(`devices/${this.serialNumber}/user-groups-features/${item.id}`)
      this.loadUserGroupFeatures()
    }
  }
}
</script>

<template>
  <tab-layout title="Funzioni gruppi utenti">
    <template v-slot:actions>
      <v-btn color="primary" @click="addFeature">
        Aggiungi
      </v-btn>
    </template>
    <v-data-table
        :headers="headers"
        :items="userGroupFeatures"
        :items-per-page="5"
    >
      <template v-slot:item.type="{ value }">
        {{ featureTypes[ value ] }}
      </template>
      <template v-slot:item.userGroup="{ value }">
        <v-chip outlined :to="'/groups-management/' + value.id">{{ value.name }}</v-chip>
      </template>
      <template v-slot:item.discount="{ value }">
        <span v-if="value">{{ value }}%</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            icon
            @click.stop="editFeature(item)"
            color="primary"
        >
          <v-icon small>fa-edit</v-icon>
        </v-btn>
        <v-btn
            icon
            @click.stop="removeFeature(item)"
            color="red"
        >
          <v-icon small>fa-trash</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <group-feature-for-device-modal
        v-model="modal.show"
        :data="modal.data"
        :edit-mode="modal.editMode"
        :serialNumber="serialNumber"
        @close="loadUserGroupFeatures"
    />
  </tab-layout>
</template>
