import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from '@/store/store'

import LocationsList from '../views/business/devices/LocationsList.vue'
import AdminsManagement from '../views/business/your_account/UsersManagement/admins/AdminsManagement'
import InstallersManagement from '../views/business/your_account/UsersManagement/installers/InstallersManagement'
import Login from '../views/Login'
import DevicesStock from '@/views/business/devices/DevicesNotActive.vue'
import PartnersList from '@/views/business/devices/PartnersList.vue'
import AccountInfo from '@/views/business/your_account/AccountInfo/AccountInfo'
import DevicesTransactions from '../views/business/devices/DevicesTransactions.vue'
import UsersVirtualKeys from '@/views/business/users/UserVirtualKeys.vue'
import TestLocalPage from '@/views/TestLocalPage'
import DeviceDetails from '@/views/commons/DeviceDetails/DeviceDetails'
import DevicesInLocationList from '@/views/business/devices/DevicesInLocationList.vue'
import FoodyTekClients from '@/views/administration/FoodyTekClients/FoodyTekClients'
import FTClientDetails from '@/views/administration/FoodyTekClients/FTClientDetails/FTClientDetails'
import Administrators from '@/views/administration/Administrators/Administrators'
import DevicesManagement from '@/views/commons/DevicesManagement/DevicesManagement'
import UsersWallet from '@/views/administration/UsersWallet/UsersWallet'
import UsersTransactions from '@/views/commons/UsersTransactions.vue'
import DeviceUpdates from '@/views/administration/DeviceUpdates/DeviceUpdates'
import UnassignedDevices from '@/views/administration/UnassignedDevices/UnassignedDevices'
import ClientCustomers from '@/views/business/your_account/UsersManagement/clientCustomers/ClientCustomers'
import RemoteTopUp from "@/views/client_customers/RemoteTopUp.vue";
import CorrispettiviAgenziaEntrate from "@/views/business/accounting/CorrispettiviAgenziaEntrate.vue";
import ClientCustomerInfo from "@/views/client_customers/ClientCustomerInfo.vue";
import clientCustomerCompleteReg from "@/views/client_customers/ClientCustomerCompleteReg.vue";
import PastTopUps from "@/views/client_customers/history/PastTopUps.vue";
import RemoteTopUpListInPayment from "@/views/client_customers/history/RemoteTopUpListInPayment.vue";
import RemoteTopUpDetails from "@/views/client_customers/history/RemoteTopUpDetails.vue";
import UserGroupsManagement from "@/views/business/users/UserGroupsManagement.vue";
import UserGroupDetails from "@/views/business/users/UserGroupDetails.vue";
import Dashboard from "@/views/business/statistics/Dashboard.vue";
import StatsPerUser from "@/views/business/statistics/StatsPerUser.vue";
import RemoteTopUps from "@/views/business/accounting/RemoteTopUps.vue";
import TopUpAndGift from "@/views/business/accounting/TopUpAndGift.vue";
import QrCodeGenerator from "@/views/administration/QrCodeGenerator.vue";

Vue.use(VueRouter)

function beforeEnterCatchDefault (to, from, next) {
  store.getters.isAuthenticated
      ? store.getters.isAdmin
          ? shouldBe(next, '/devices', to)
          : store.getters.isClient
              ? shouldBe(next, '/dashboard', to)
              : store.getters.ccRegComplete
                  ? shouldBe(next, '/client-customers/info', to)
                  : shouldBe(next, '/client-customers/complete-registration', to)
      : shouldBe(next, '/logout', to)
}

function getBeforeEnterForAdminAndClientOwner (clientIdField) {
  return (to, from, next) => {
    if (!store.getters.isAdmin && (!store.getters.isClient || store.getters.clientId !== to.params[clientIdField])) {
      console.log('Only admin and client allowed')
      beforeEnterCatchDefault(to, from, next)
      return
    }

    beforeEnter(to, from, next)
  }
}

function beforeEnterAdminAndClient (to, from, next) {
  if (!store.getters.isAdmin && !store.getters.isClient) {
    console.log('Only admin and client allowed')
    beforeEnterCatchDefault(to, from, next)
    return
  }

  beforeEnter(to, from, next)
}

function beforeEnterOnlyAdmin (to, from, next) {
  if (!store.getters.isAdmin) {
    console.log('Only admin allowed')
    beforeEnterCatchDefault(to, from, next)
    return
  }

  beforeEnter(to, from, next)
}

function beforeEnterOnlyClient (to, from, next) {
  if (!store.getters.isClient) {
    console.log('Only client allowed')
    beforeEnterCatchDefault(to, from, next)
    return
  }

  beforeEnter(to, from, next)
}

function beforeEnterOnlyClientCustomer (to, from, next) {
  if (!store.getters.isClientCustomer) {
    console.log('Only client customer allowed')
    beforeEnterCatchDefault(to, from, next)
    return
  }

  const compReg = '/client-customers/complete-registration'
  store.getters.isAuthenticated
      ? store.getters.registrationComplete
          ? store.getters.ccRegComplete
              ? shouldNotBe(next, compReg, to) : shouldBe(next, compReg, to)
          : shouldBe(next, '/logout', to)
      : shouldBe(next, '/login', to)
}

function beforeEnter (to, from, next) {
  store.getters.isAuthenticated
      ? store.getters.registrationComplete ? next() : shouldBe(next, '/logout', to)
      : shouldBe(next, '/login', to)
}

function shouldBe (next, shouldBe, to) {
  if (to.path === shouldBe) next()
  else next(shouldBe)
}

function shouldNotBe (next, shouldNotBe, to) {
  if (to.path === shouldNotBe) next('/')
  else next()
}

export const routes = [
  {
    path: '/',
    beforeEnter: beforeEnterCatchDefault
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    beforeEnter (to, from, next) {
      store.commit('logoutUser')
      next('/login')
    }
  },
  {
    path: '/test-local-page',
    component: TestLocalPage,
    beforeEnter
  },

  // ----------------------- Dashboard -----------------------
  {
    path: '/dashboard',
    component: Dashboard,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/dashboard/per-user',
    component: StatsPerUser,
    beforeEnter: beforeEnterOnlyClient
  },

  // ----------------------- Account -----------------------
  {
    path: '/my-account',
    component: AccountInfo,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/users/installers',
    component: InstallersManagement,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/users/admins',
    component: AdminsManagement,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/users/client-customers',
    component: ClientCustomers,
    beforeEnter: beforeEnterOnlyClient
  },

  // ----------------------- Accounting -----------------------
  {
    path: '/corrispettivi',
    component: CorrispettiviAgenziaEntrate,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/remote-top-ups',
    component: RemoteTopUps,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/top-up-and-gift',
    component: TopUpAndGift,
    beforeEnter: beforeEnterOnlyClient
  },

  // ----------------------- Devices -----------------------
  {
    path: '/partners',
    component: PartnersList,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/partners/:partnerId',
    component: LocationsList,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/locations/:locationId',
    component: DevicesInLocationList,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/devices/not-active',
    component: DevicesStock,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/devices/transactions',
    component: DevicesTransactions,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/devices/unassigned',
    component: UnassignedDevices,
    beforeEnter: beforeEnterOnlyAdmin
  },
  {
    path: '/devices',
    component: DevicesManagement,
    beforeEnter: beforeEnterAdminAndClient
  },
  {
    path: '/devices/:serialNumber',
    component: DeviceDetails,
    beforeEnter: beforeEnterAdminAndClient
  },

  // ----------------------- Users -----------------------
  {
    path: '/virtual-keys',
    component: UsersVirtualKeys,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/groups-management',
    component: UserGroupsManagement,
    beforeEnter: beforeEnterOnlyClient
  },
  {
    path: '/groups-management/:groupId',
    component: UserGroupDetails,
    beforeEnter: beforeEnterOnlyClient
  },

  // ----------------------- Client Customers -----------------------
  {
    path: '/client-customers/complete-registration',
    component: clientCustomerCompleteReg,
    beforeEnter: beforeEnterOnlyClientCustomer
  },
  {
    path: '/client-customers/info',
    component: ClientCustomerInfo,
    beforeEnter: beforeEnterOnlyClientCustomer
  },
  {
    path: '/client-customers/remote-top-up',
    component: RemoteTopUp,
    beforeEnter: beforeEnterOnlyClientCustomer
  },
  {
    path: '/client-customers/past-top-ups',
    component: PastTopUps,
    beforeEnter: beforeEnterOnlyClientCustomer
  },
  {
    path: '/client-customers/sepa-payments/:paymentId',
    name: 'sepa-payments-details',
    component: RemoteTopUpListInPayment,
    beforeEnter: beforeEnterOnlyClientCustomer
  },
  {
    path: '/client-customers/past-top-ups/:topUpId',
    name: 'top-up-details',
    component: RemoteTopUpDetails,
    beforeEnter: beforeEnterOnlyClientCustomer
  },

  // ----------------------- Administration -----------------------
  {
    path: '/ft-clients',
    component: FoodyTekClients,
    beforeEnter: beforeEnterOnlyAdmin
  },
  {
    path: '/ft-clients/:ftClientId',
    component: FTClientDetails,
    beforeEnter: beforeEnterOnlyAdmin
  },
  {
    path: '/administrators',
    component: Administrators,
    beforeEnter: beforeEnterOnlyAdmin
  },
  {
    path: '/users-wallet',
    component: UsersWallet,
    beforeEnter: beforeEnterOnlyAdmin
  },
  {
    path: '/users-wallet/:ftClientId',
    component: UsersWallet,
    beforeEnter: beforeEnterOnlyAdmin
  },
  {
    path: '/users-wallet/:ftClientId/transactions/:userId', // fixme: change path
    component: UsersTransactions,
    beforeEnter: getBeforeEnterForAdminAndClientOwner('ftClientId')
  },
  {
    path: '/device-updates',
    component: DeviceUpdates,
    beforeEnter: beforeEnterOnlyAdmin
  },
  {
    path: '/qr-code-generator',
    component: QrCodeGenerator,
    beforeEnter: beforeEnterOnlyAdmin
  },

  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
