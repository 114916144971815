<template>
  <v-text-field
      v-model.number="price"
      :rules="rules"
      append-icon="fa-euro-sign"
      type="number"
      :dense="dense"
      :outlined="outlined"
  >
  </v-text-field>
</template>

<script>
export default {
  name: 'PriceTextField',
  props: {
    value: {},
    label: String,
    rules: Array,
    dense: Boolean,
    outlined: Boolean
  },
  computed: {
    price: {
      get () {
        return this.value / 100
      },
      set (newValue) {
        this.$emit('input', Math.round(newValue * 100))
      }
    }
  }
}
</script>

<style scoped>
</style>
