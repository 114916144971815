<template>
  <v-icon :color="value ? 'green' : 'red'">{{ value ? 'fa-check' : 'fa-times' }}</v-icon>
</template>

<script>
export default {
  name: 'BooleanIcon',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>
