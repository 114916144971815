<template>
  <div>
    <v-card class="mt-8 elevation-8 mx-auto mb-7" style="width: 90%">
      <v-card-title class="d-block text-center pt-6 pb-5 text-h4">
        Gestione gruppi
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center mb-4 mx-5">
          <v-text-field
              v-model="search"
              append-icon="fa-search"
              outlined
              :label="$t('commons.search')"
              single-line
              hide-details/>
          <v-btn
              class="ml-6"
              color="green" dark
              v-text="$t('commons.create')"
              @click="create"
          />
        </div>
        <v-data-table
            :items="groups"
            :headers="headers"
            :search="search"
            item-key="item.id"
            no-data-text="Non c'è ancora nessun gruppo. Crea il primo ora!"
        >
          <template v-slot:item.name="{ item }">
            <v-chip outlined :to="'groups-management/' + item.id">{{ item.name }}</v-chip>
          </template>
          <template v-slot:item.crud="{ item }">
            <v-btn color="primary"
                   class="pa-5"
                   small icon
                   @click="edit(item)">
              <v-icon small>fa-edit</v-icon>
            </v-btn>
            <v-btn color="red"
                   class="pa-5"
                   small icon
                   @click="remove(item)">
              <v-icon small>fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <user-group-modal
        v-model="modal.opened"
        :data="modal.item"
        :edit-mode="modal.editMode"
        @close="reload"
    />
  </div>
</template>

<script>
import UserGroupModal from "@/components/modals/business/UserGroupModal.vue";

export default {
  name: 'UserGroupsManagement',
  components: { UserGroupModal },
  data () {
    return {
      groups: [],
      search: '',
      modal: {
        opened: false,
        editMode: false,
        item: {}
      }
    }
  },
  computed: {
    tokenHeaders () {
      return [
        { text: 'Email', value: 'email' },
        { text: '', value: 'crud' }
      ]
    },
    headers () {
      return [
        { text: this.$t('commons.data.name'), value: 'name' },
        { text: this.$t('userGroups.fields.notes'), value: 'notes' },
        { text: '', value: 'crud' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getGroups()
    },
    reload () {
      setTimeout(this.init, 400)
      setTimeout(this.init, 1000)
    },
    async getGroups () {
      try {
        const response = await this.$http.auth.get('/user-groups')
        this.groups = response.data
      } catch (e) {
      }
    },

    create() {
      this.modal.editMode = false
      this.modal.opened = true
    },
    edit(item) {
      this.modal.editMode = true
      this.modal.item = Object.assign({}, item)
      this.modal.opened = true
    },
    async remove(item) {
      try {
        const res = await this.$dialog.confirm({
          title: 'Attenzione!',
          text: `${this.$t('userGroups.messages.confirmDeletion')} '${item.name}'?`
        })
        if (!res) return
        console.log(res)
        await this.$http.auth.delete('user-groups/' + item.id)
        this.reload()
      } catch (e) {
      }
    },
  }
}
</script>
