<template>
  <action-modal
      v-model="fieldValue"
      :data="data"
      :edit-mode="editMode"
      :submit-btn-text="$t('commons.create')"
      :edit-btn-text="$t('commons.edit')"
      :cancel-btn-text="$t('commons.cancel')"
      :loading-text="$t('commons.loading')"

      :submit-title="$t('groupFeatures.modal.createTitle')"
      :edit-title="$t('groupFeatures.modal.editTitle')"
      :submit-success-message="$t('groupFeatures.messages.created')"
      :edit-success-message="$t('groupFeatures.messages.edited')"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="createLocationCallback"
      :edit-promise-callback="editLocationCallback"
      @close="close"
  >
    <template v-slot:field.type="{ errors, label, classes }">
      <v-select
          v-model="data.type"
          :items="features"
          outlined
          :class="classes"
          :label="label"
          :error-messages="errors"
      />
    </template>
    <template v-slot:field.userGroupId="{ errors, label, classes }">
      <div>
        <v-select
            v-model="data.userGroupId"
            :items="userGroups"
            outlined
            :class="classes"
            :label="label"
            :error-messages="errors"
        />
      </div>
    </template>
    <template v-slot:field.discount="{ errors, label, classes }">
      <div>
        <v-text-field
            v-model="data.discount"
            :items="userGroups"
            outlined
            messages="Utilizza il punto e non la virgola per i decimali."
            :class="classes"
            :label="label"
            :error-messages="errors"
        />
      </div>
    </template>
  </action-modal>
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'

export default {
  name: 'GroupFeatureForDeviceModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean
    },
    serialNumber: {
      type: String,
      required: true
    },
    data: {
      type: Object
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        // md: 6
      },
      features: [
        { text: 'Accesso/utilizzo', value: "ACCESS" },
        { text: "Sconto", value: "DISCOUNT"}
      ],
      userGroups: []
    }
  },
  mounted () {
    this.loadUserGroups()
  },
  computed: {
    fields () {
      return [
        { value: 'type', label: 'Funzione', rules: 'required' },
        { value: 'userGroupId', label: 'Gruppo utenti', rules: 'required' },
        { value: 'discount', label: 'Percentuale sconto', rules: 'required', type: 'number', hide: this.data.type !== 'DISCOUNT' },
        { value: 'description', label: 'Descrizione (opzionale)', rules: '' },
      ].filter(i => !i.hide)
    },
    fieldValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    async loadUserGroups () {
      const res = await this.$http.auth.get('/user-groups')
      this.userGroups = res.data.map(ug => ({ text: ug.name, value: ug.id }))
    },
    createLocationCallback () {
      return this.$http.device.post(
        `devices/${this.serialNumber}/user-groups-features`,
        this.data
      )
    },
    editLocationCallback () {
      return this.$http.device.put(
          `devices/${this.serialNumber}/user-groups-features/${this.data.id}`,
        this.data
      )
    },
    close () {
      this.$emit('close')
    },
  }
}
</script>
