<template>
  <div>
    <div class="text-h4">
      <v-btn icon @click="$router.back()">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>
      <span class="ms-3">
        Dispositivi in
        <span class="font-weight-bold">{{ partnerName }}</span>
        <v-icon class="mx-1">fa-arrow-right</v-icon>
        <span class="font-weight-bold">{{ locationName }}</span>
      </span>
    </div>

    <div class="my-3 text-caption">
      Nota bene: i dati mostrati sono quelli dell'ultima attivazione del dispositivo effettuata,
      pertanto i dispositivi mai attivati non presentano alcun dato
    </div>

    <v-card outlined>
      <v-data-table
          hide-default-footer
          :items="devices"
          :headers="headers"
          :items-per-page="itemsPerPage"
          item-key="serialNumber"
          class="row-pointer"
          @click:row="handleRowClick"
      >
        <template v-slot:item.active="{ value }">
          <v-icon :color="value ? 'green' : 'red'">{{ value ? 'fa-check' : 'fa-times' }}</v-icon>
        </template>
        <template v-slot:item.configured="{ value }">
          <v-icon :color="value ? 'green' : 'red'">{{ value ? 'fa-check' : 'fa-times' }}</v-icon>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn small icon color="primary"
                     @click.stop="openQRCode(item)"
                     v-bind="attrs"
                     v-on="on"
              >
                <v-icon>
                  fa-qrcode
                </v-icon>
              </v-btn>
            </template>
            <span>Download QR-Code</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <device-q-r-code-modal v-model="qrCode"/>
  </div>
</template>

<script>
import DeviceQRCodeModal from "@/components/modals/commons/DeviceQRCodeModal.vue";

export default {
  name: 'DevicesInLocationList',
  components: { DeviceQRCodeModal },
  data () {
    return {
      devices: [],
      partnerName: null,
      locationName: null,
      itemsPerPage: -1,
      qrCode: {
        show: false,
        macAddress: null,
        serialNumber: null,
      },
    }
  },
  computed: {
    headers () {
      return [
        { value: 'serialNumber', text: this.$t('devicesStock.serialNumber') },
        { value: 'name', text: 'Nome' },
        { value: 'description', text: 'Descrizione' },
        { value: 'version', text: 'Versione firmware' },
        { value: 'active', text: 'Attivo' },
        { value: 'configured', text: 'Configurato' },
        { value: 'actions', text: '' },
      ]
    }
  },
  mounted () {
    this.getNonActiveDevices()
  },
  methods: {
    async getNonActiveDevices () {
      try {
        const response = await this.$http.device('devices/locations/' + this.$route.params.locationId)
        this.partnerName = response.data.partner.name
        this.locationName = response.data.name
        this.devices = response.data.devices
      } catch (e) {
      }
    },
    handleRowClick (device) {
      this.$router.push(`/devices/${device.serialNumber}`)
    },
    openQRCode (item) {
      this.qrCode.macAddress = item.macAddress
      this.qrCode.serialNumber = item.serialNumber
      this.qrCode.show = true
    },
  }
}
</script>

<style scoped>

</style>
