<template>
  <div>
    <v-btn @click="show = true" outlined>Sticker QR download app e registrazione</v-btn>

    <v-dialog v-model="show" :max-width="containerWidth + 14" scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Sticker QR download app e registrazione
        </v-card-title>

        <v-checkbox class="mx-4" v-model="includeBorders" label="Aggiungi bordi per la stampa"/>

        <div class="white" style="height: 73vh; width: 100%; position: absolute; margin-top: 10vh;" v-if="downloading">
        </div>

        <div style="height: 80vh" class="overflow-y-auto">
          <div id="qr-container" ref="qrContainer" class="text-center"
               :style="qrContainerStyle">
            <div id="qr-wrapper" :style="qrWrapperStyle">
              <div id="qr-code" ref="qrCode"></div>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
              color="primary"
              text
              @click="show = false"
          >
            {{ $t('commons.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="downloadQRCodePNG($refs.qrContainer)"
          >
            {{ $t('qrCodeModal.downloadPng') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="downloadQRCodePDF($refs.qrContainer)"
          >
            {{ $t('qrCodeModal.downloadPdf') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'svg2pdf.js'
import html2canvas from 'html2canvas'
import QRCodeStyling from 'qr-code-styling'

export default {
  name: 'BaseQRCodeStickerModal',
  props: {
    qrBaseSuffix: {
      type: String,
      default: 'enjoy-a5'
    },
    containerWidth: {
      type: Number,
      default: 916 // 1004
    },
    containerHeight: {
      type: Number,
      default: 1300
    },
    qrPaddingTop: {
      type: Number,
      default: 185
    },
    qrSize: {
      type: Number,
      default: 440
    },
    qrContent: {
      type: String,
      default: 'https://foodytek.com/download'
    },
    downloadFilenameSuffix: String, // set default in mounted
  },
  data: () => {
    return {
      show: false,
      downloadSizeMultiplier: 1.91,
      downloading: false,
      includeBorders: false,
      qrCode: null,
      childHasToBeCreated: true,
    }
  },
  mounted () {
    this.qrCode = new QRCodeStyling(this.qrOptions)
  },
  computed: {
    bgImage () {
      return require(`@/assets/qr-base-images/qr-base-${this.qrBaseSuffix}.png`)
    },
    qrOptions () {
      const mul = this.downloading ? this.downloadSizeMultiplier : 1
      const size = this.qrSize * mul
      return {
        width: size,
        height: size,
        data: this.qrContent,
        image: require('@/assets/logo.png'),
        margin: 10,
        type: 'svg',
        qrOptions: { typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' },
        imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 20 * mul },
        dotsOptions: {
          type: 'dots',
          color: '#1d355c',
          gradient: null
        },
        backgroundOptions: { color: '#ffffff' },
        cornersSquareOptions: { type: 'extra-rounded', color: '#1d355c' },
        cornersDotOptions: {
          type: 'circle',
          color: '#1d355c'
        }
      }
    },
    qrContainerStyle () {
      const mul = this.downloading ? this.downloadSizeMultiplier : 1
      return {
        width: `${this.containerWidth * mul}px`,
        height: `${this.containerHeight * mul}px`,
        'background-image': `url(${this.bgImage})`,
        border: this.includeBorders
            ? '1px solid black'
            : ''
      }
    },
    qrWrapperStyle () {
      const mul = this.downloading ? this.downloadSizeMultiplier : 1
      return {
        'padding-top': `${this.qrPaddingTop * mul}px`,
      }
    },
  },
  watch: {
    'show': async function (newValue) {
      if (!newValue) return
      // this.link = ''
      if (this.childHasToBeCreated) {
        this.childHasToBeCreated = false
        await this.sleep(100)
        this.qrCode.append(this.$refs[ 'qrCode' ])
      }
      await this.sleep(100)
      this.updateQR()
    }
  },
  methods: {
    async downloadQRCodePDF () {
      const qr = this.$refs.qrContainer
      this.downloading = true
      this.updateQR()
      this.$nextTick(async () => {
        await this.sleep(200)
        const that = this
        html2canvas(qr, { allowTaint: true }).then(function (canvas) {
          // Generate and use PNG and inject it into a PDF

          const imgData = canvas.toDataURL('image/png')
          // Create a new jsPDF instance (A4 page size: 210 x 297 mm)
          const pdf = new jsPDF({
            // orientation: 'portrait',
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
          });

          // Define dimensions for A5 size (148 x 210 mm)
          const a5Width = 148; // in mm
          const a5Height = 210; // in mm

          // Add the first A5 image on the left side of the page
          pdf.addImage(imgData, 'PNG', 0, 0, a5Width, a5Height);

          // Add the second A5 image on the right side of the page
          pdf.addImage(imgData, 'PNG', a5Width, 0, a5Width, a5Height);

          // Save the PDF
          const customSuffix = that.downloadFilenameSuffix ? '-' + that.downloadFilenameSuffix : ''
          pdf.save(`qr-code-${that.qrBaseSuffix}${customSuffix}-printable.pdf`);

          // Generate and use SVG and inject it into a PDF
        })
        this.downloading = false
        this.updateQR()
      })
    },
    async downloadQRCodePNG () {
      const qr = this.$refs.qrContainer
      this.downloading = true
      this.updateQR()
      this.$nextTick(async () => {
        await this.sleep(200)
        const that = this
        html2canvas(qr, { allowTaint: true }).then(function (canvas) {
          const link = document.createElement('a')
          const customSuffix = that.downloadFilenameSuffix ? '-' + that.downloadFilenameSuffix : ''
          document.body.appendChild(link)
          link.download = `qr-code-${that.qrBaseSuffix}${customSuffix}.png`
          link.href = canvas.toDataURL()
          link.target = '_blank'
          link.click()
          link.remove()
        })
        this.downloading = false
        this.updateQR()
      })
    },
    updateQR () {
      this.qrCode.update(this.qrOptions)
    },
    sleep (time) {
      return new Promise(resolve => {
        setTimeout(resolve, time)
      })
    }
  }
}
</script>

<style scoped lang="scss">

#qr-wrapper {
  //padding-top: 185px;
  //padding-top: 370px;
}

#qr-container {
  //width: 916px;
  //height: 1300px;
  //width: 1833px;
  //height: 2600px;
  background: center no-repeat;
  background-size: cover;
}

</style>
