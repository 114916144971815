var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h4"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("fa-arrow-left")])],1),_c('span',{staticClass:"ms-3"},[_vm._v(" Dispositivi in "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.partnerName))]),_c('v-icon',{staticClass:"mx-1"},[_vm._v("fa-arrow-right")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.locationName))])],1)],1),_c('div',{staticClass:"my-3 text-caption"},[_vm._v(" Nota bene: i dati mostrati sono quelli dell'ultima attivazione del dispositivo effettuata, pertanto i dispositivi mai attivati non presentano alcun dato ")]),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"hide-default-footer":"","items":_vm.devices,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"item-key":"serialNumber"},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value ? 'green' : 'red'}},[_vm._v(_vm._s(value ? 'fa-check' : 'fa-times'))])]}},{key:"item.configured",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value ? 'green' : 'red'}},[_vm._v(_vm._s(value ? 'fa-check' : 'fa-times'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openQRCode(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-qrcode ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download QR-Code")])])]}}])})],1),_c('device-q-r-code-modal',{model:{value:(_vm.qrCode),callback:function ($$v) {_vm.qrCode=$$v},expression:"qrCode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }