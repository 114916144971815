<template>
  <div>
    <h1>QR Code Generator</h1>

    <v-text-field outlined class="mx-5" v-model="options.data"/>

    <v-btn @click="show">Generate QR Code</v-btn>

    <div class="ma-2">
      <div class="d-inline-block pa-2" id="qr-code" ref="qrCode"></div>
    </div>

    <v-btn v-if="qrShowed" @click="downloadQRCode">Download QR Code</v-btn>

    <q-r-code-enjoy-sticker-modal />
  </div>
</template>

<script>

import html2canvas from "html2canvas";
import QRCodeStyling from "qr-code-styling";
import QRCodeEnjoyStickerModal from "@/components/modals/commons/BaseQRCodeStickerModal.vue";

export default {
  name: 'QrCodeGenerator',
  components: { QRCodeEnjoyStickerModal },
  data () {
    const options = {
      width: 460,
      height: 460,
      data: 'https://www.foodytek.com/download',
      image: require('@/assets/logo.png'),
      margin: 10,
      qrOptions: { typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 20 },
      dotsOptions: {
        type: 'dots',
        color: '#1d355c',
        gradient: null
      },
      backgroundOptions: { color: '#ffffff' },
      cornersSquareOptions: { type: 'extra-rounded', color: '#1d355c' },
      cornersDotOptions: {
        type: 'circle',
        color: '#1d355c'
      }
    }
    return {
      options,
      qrShowed: false,
      qrCode: new QRCodeStyling(options),
      res: null
    }
  },
  mounted () {
    this.qrCode.append(this.$refs.qrCode)
  },
  methods: {
    show () {
      this.qrShowed = true
      this.qrCode.update(this.options)
    },
    downloadQRCode () {
      const qr = this.$refs.qrCode
      html2canvas(qr, { allowTaint: true }).then(function (canvas) {
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.download = 'qr-code.png'
        link.href = canvas.toDataURL()
        link.target = '_blank'
        link.click()
        link.remove()
      })
    },
  }
}
</script>
