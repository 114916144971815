var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"devicesStock"}},[_c('v-card',{staticClass:"mt-8 elevation-8 mx-auto mb-7",staticStyle:{"width":"90%"}},[_c('v-card-title',{staticClass:"d-block text-center pt-6 pb-5 text-h4"},[_vm._v(" "+_vm._s(_vm.$t('unassignedDevices.title'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.devices,"headers":_vm.createdDevicesTableFields,"item-key":"item.id"},scopedSlots:_vm._u([{key:"item.creationDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openQRCode(item.macAddress)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-qrcode ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download QR-Code")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.copyNFCUrl(item.macAddress)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-brands fa-nfc-symbol ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy NFC Tag URL")])]),_c('v-btn',{staticClass:"pa-5",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.openAssignDeviceModal(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-user-check")])],1)]}}])})],1)],1),_c('assign-device-modal',{attrs:{"serial-number":_vm.assignDeviceSerialNumber},on:{"update:serialNumber":function($event){_vm.assignDeviceSerialNumber=$event},"update:serial-number":function($event){_vm.assignDeviceSerialNumber=$event},"close":_vm.closeAssignDevice}}),_c('device-q-r-code-modal',{model:{value:(_vm.qrCode),callback:function ($$v) {_vm.qrCode=$$v},expression:"qrCode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }