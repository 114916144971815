<script>
import DayOrMonthSelector from "@/views/business/statistics/DayOrMonthSelector.vue";

export default {
  name: "StatsPerUser",
  components: { DayOrMonthSelector },
  data () {
    return {
      items: [],
      selectedDate: null,
      headers: [
        { value: 'email', text: 'Email' },
        { value: 'name', text: 'Nome' },
        { value: 'surname', text: 'Cognome' },
        { value: 'purchasesTotal', text: 'Acquisti Apollo' },
        { value: 'cashTopUpTotal', text: 'Ricariche cash' },
        { value: 'cardTopUpTotal', text: 'Ricariche carta' },
        { value: 'giftTopUpTotal', text: 'Ricariche regalo' },
      ]
    }
  },
  methods: {
    async loadData (periodType) {
      try {
        const r = await this.$http.reports('v2/dashboard/stats-per-user', {
          params: {
            [ periodType === 'day' ? 'date' : 'month' ]: this.selectedDate,
          }
        })

        this.items = r.data
      } catch (e) {
      }
    },
    openTransactions (row) {
      this.$router.push(`/users-wallet/${this.$store.getters.clientId}/transactions/${row.userId}`)
    },
  }
}
</script>

<template>
  <div>
    <h1 class="mb-3">Statistiche per utente</h1>

    <day-or-month-selector v-model="selectedDate" :fetch-data="loadData"/>

    <v-card outlined>
      <v-data-table
          :items="items"
          :headers="headers"
          item-key="to"
      >
        <template v-slot:item.serialNumber="{ value }">
          <v-chip link outlined :to="`/devices/${value}`">{{ value }}</v-chip>
        </template>

        <template v-slot:item.email="{ item, value }">
          <v-chip
              outlined
              @click="openTransactions(item)"
          >
            {{ value }}
          </v-chip>
        </template>

        <template v-slot:item.purchasesTotal="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.cashTopUpTotal="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.cardTopUpTotal="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:item.giftTopUpTotal="{ value }">
          {{ formatMoney(value) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
