<template>
  <v-dialog v-model="value.show" max-width="1020" scrollable>
    <v-card>
      <v-card-title class="text-h5 primary lighten-1 white--text">
        QR Code {{ value.serialNumber }}
      </v-card-title>

      <v-alert color="grey lighten-3">
        Attenzione!
        Il QR di registrazione (da mettere per esempio in segreteria), è stato spostato nella <router-link to="/my-account">pagina del proprio account</router-link>, perché non riguarda uno specifico dispositivo.
        <v-btn text to="/my-account">Vai alla pagina del mio account</v-btn>
      </v-alert>

      <v-checkbox class="mx-4" v-model="includeBorders" label="Aggiungi bordi per la stampa"/>

      <div style="height: 80vh" class="overflow-y-auto">
        <div id="qr-container" ref="qrContainer" class="text-center"
             :style="{'background-image': 'url(' + bgImage + ')'}">
          <div id="qr-wrapper">
            <div id="qr-code" ref="qrCode"></div>
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="value.show = false"
        >
          {{ $t('commons.close') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="downloadQRCode($refs.qrContainer)"
        >
          {{ $t('qrCodeModal.download') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import html2canvas from 'html2canvas'
import QRCodeStyling from 'qr-code-styling'

export default {
  name: 'QRCodeStickerModal',
  props: {
    value: Object,
    type: Array,
    showPay: {
      type: Boolean,
      default: true
    },
    showEnjoy: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    const options = {
      width: 460,
      height: 460,
      data: null,
      image: require('@/assets/logo.png'),
      margin: 10,
      qrOptions: { typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 20 },
      dotsOptions: {
        type: 'dots',
        color: '#1d355c',
        gradient: null
      },
      backgroundOptions: { color: '#ffffff' },
      cornersSquareOptions: { type: 'extra-rounded', color: '#1d355c' },
      cornersDotOptions: {
        type: 'circle',
        color: '#1d355c'
      }
    }
    return {
      options,
      tab: 0,
      includeBorders: false,
      qrCode: new QRCodeStyling(options),
      childHasToBeCreated: true,
      link: 'https://foodytek.com/connect?device=',
    }
  },
  computed: {
    macAddress () {
      return this.value.macAddress || ''
    },
    bgImage () {
      return require(`@/assets/qr-base-images/qr-base-pay${this.includeBordersTextLink}.png`)
    },
    includeBordersTextLink () {
      return this.includeBorders
          ? '-borders'
          : ''
    }
  },
  watch: {
    'value.show': async function (newValue) {
      if (!newValue) return
      this.options.data = this.link + this.macAddress
      if (this.childHasToBeCreated) {
        this.childHasToBeCreated = false
        await this.sleep(200)
        // eslint-disable-next-line dot-notation
        this.qrCode.append(this.$refs[ 'qrCode' ])
      }
      await this.sleep(200)
      this.qrCode.update(this.options)
    }
  },
  methods: {
    downloadQRCode () {
      const qr = this.$refs.qrContainer
      const that = this
      html2canvas(qr, { allowTaint: true }).then(function (canvas) {
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.download = ('qr-code-' + that.macAddress + (that.tab ? '-registrati' : ''))
            .toString()
            .replaceAll(':', '') + '.png'
        link.href = canvas.toDataURL()
        link.target = '_blank'
        link.click()
        link.remove()
      })
    },
    sleep (time) {
      return new Promise(resolve => {
        setTimeout(resolve, time)
      })
    }
  }
}
</script>

<style scoped lang="scss">

#qr-wrapper {
  padding-top: 210px;
}

#qr-container {
  width: 1004px;
  height: 1300px;
  background: center no-repeat;
  background-size: cover;
}

</style>
