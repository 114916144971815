<script>
import EmailModal from "@/components/modals/clients_customers/EmailModal.vue";
import {ValidationProvider} from "vee-validate";

export default {
  name: "RemoteTopUp",
  components: { ValidationProvider, EmailModal },
  data () {
    return {
      selected: [],
      amountInEuro: null,
      search: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Surname', value: 'surname' },
        { text: 'Email', value: 'email' },
      ],
      items: []
    }
  },
  mounted () {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers () {
      const response = await this.$http.payment.get('/remote-top-ups/visible-users')
      this.items = response.data
    },
    performTopUp () {
      if (this.selected.length === 0) {
        this.$dialog.error({
          title: 'Attenzione',
          text: 'Seleziona almeno un utente',
        })
        return
      }

      this.$dialog.confirm({
        title: 'Conferma',
        text: `<p>Vuoi effettuare la ricarica di <b>${this.amountInEuro} euro</b> ad ognuno dei seguenti utenti? <br>
                <small>${this.selected.map(user => `<i>${user.email}</i>`).join(', ')}</small></p>
                <p>L'importo totale che verrà addebitato a fine mese per questa ricarica è di <b>${this.amountInEuro * this.selected.length} euro</b>.</p>
        `,
        actions: [
          {
            text: 'Annulla'
          },
          {
            text: 'Conferma',
            handler: this.performTopUpConfirmed
          }
        ],
      })
    },
    async performTopUpConfirmed () {
      await this.$http.payment.post('/remote-top-ups', {
        userIds: this.selected.map(user => user.id),
        amount: this.amountInEuro * 100
      })
    }
  },
}
</script>

<template>
  <div>
    <h1>Ricarica remota</h1>
    <v-card color="grey lighten-2" elevation="0" class="mx-4" style="max-width: 500px">
      <v-card-text>
        Per procedere con la ricarica remota:
        <ol>
          <li>Aggiungere gli utenti desiderati tramite email.</li>
          <li>Selezionare gli utenti a cui si vuole effettuare la ricarica</li>
          <li>Premere il pulsante "Ricarica"</li>
        </ol>

      </v-card-text>
    </v-card>

    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>Utenti</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <email-modal @close="fetchUsers"/>
        </v-toolbar>
        <v-text-field
            v-model="search"
            outlined
            class="mx-10"
            label="Cerca utente"
            dense
        />
      </template>
    </v-data-table>

    <v-card outlined class="mx-auto" style="max-width: 400px">
      <v-card-text>
        <div class="text-body-1 mb-2">Hai selezionato <b>{{ selected.length }} utenti</b></div>

        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
              rules="required|min_value:5|max_value:100"
              name="Prezzo"
              v-slot="{ errors }"
          >
            <slot v-bind="{ errors }">
              <v-text-field
                  v-model.number="amountInEuro"
                  :error-messages="errors"
                  type="number"
                  prefix="€"
                  outlined
              >
              </v-text-field>
            </slot>
          </validation-provider>

          <div class="text-right">
            <v-btn color="success" @click="handleSubmit(performTopUp)">
              Ricarica
            </v-btn>
          </div>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>
