<template>
  <div>
    <div class="text-center">
      <v-btn
          color="primary"
          @click="getDeviceEvents"
      >
        Load events
      </v-btn>
    </div>

    <v-row v-if="devices !== null" style="max-width: 50rem" class="mx-auto mt-3">
      <v-col cols="12">
        <v-select
            v-model="filters.types"
            :items="types"
            label="Types"
            multiple
            small-chips
            chips
        ></v-select>
      </v-col>
    </v-row>

    <v-data-table
        v-if="devices !== null"
        :items="devicesFiltered"
        :headers="headers"
        item-key="index"
    >
      <template v-slot:item.instant="{ value }">
        {{ formatDateTime(value) }}
      </template>
      <template v-slot:item.amount="{ value, item }">
        {{ !['CONNECT', 'DISCONNECT'].includes(item.type) ? formatMoney(value) : '' }}
      </template>
      <template v-slot:item.counter="{ value }">
        {{ value ? formatMoney(value) : '' }}
      </template>
      <template v-slot:item.user="{ value }">
        <v-tooltip bottom v-if="value">
          <template v-slot:activator="{ on }">
            <v-chip link outlined v-on="on" @click="copyTextToClipboard(value.id)">{{ value.email }}</v-chip>
          </template>
          <span>
            <b>{{value.name}} {{value.surname}}</b>
            <br>
            Click to copy id:
            <br>
            {{ value.id }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'DeviceEvents',
  props: {
    serialNumber: {
      type: String,
      required: true
    },
    fromDate: {
      type: Object,
      required: true
    },
    toDate: {
      type: Object,
      required: true
    }
  },
  data () {
    const types = [
      'CONNECT',
      'DISCONNECT',

      'OP_PURCHASE',
      'OP_PURCHASE_REFUND',
      'OP_TOP_UP',

      'COUNT_PURCHASE',
      'COUNT_TOP_UP',
      'COUNT_LEGACY_PURCHASE'
    ]

    return {
      devices: null,
      headers: [
        { value: 'instant', text: 'Date' },
        { value: 'type', text: 'Type' },
        { value: 'amount', text: 'Amount or counter diff.' },
        { value: 'counter', text: 'Counter' },
        { value: 'user', text: 'User' }
      ].map(i => {
        i.sortable = false
        return i
      }),
      types,
      areCountersOptions: [
        { text: 'All', value: null },
        { text: 'Counters', value: true },
        { text: 'Operations', value: false }
      ],
      filters: {
        areCounters: null,
        types
      }
    }
  },
  mounted () {
    this.getDeviceEvents()
  },
  computed: {
    devicesFiltered () {
      return this.devices.filter(d =>
        (this.filters.areCounters === null || this.filters.areCounters === d.areCounters) &&
          (this.filters.types.includes(d.type))
      )
    }
  },
  methods: {
    getDateFromObj (d) {
      const [year, month, day] = d.date.split('-')
      const [hour, min] = d.time.split(':')

      const date = new Date()
      date.setFullYear(year, month - 1, day)
      date.setHours(hour, min)
      return date.toISOString()
    },
    async getDeviceEvents () {
      try {
        const fromDate = this.getDateFromObj(this.fromDate)
        const toDate = this.getDateFromObj(this.toDate)
        const response = await this.$http.device.get(`devices/${this.serialNumber}/events/${fromDate}/${toDate}`)
        this.devices = response.data
        this.devices.forEach((item, i) => {
          item.index = i
        })
      } catch (err) {
      }
    },
    async getSerialNumbers () {
      try {
        const response = await this.$http.device.get('queries/devices/serial-number')
        this.allSerialNumbers = response.data.map(i => i.serialNumber)
      } catch (err) {
      }
    }
  }
}
</script>

<style scoped>

</style>
