<template>
  <action-modal
      v-if="show "
      v-model="show"
      :data="data"
      :edit-mode="false"
      submit-btn-text="Aggiungi"
      :cancel-btn-text="$t('commons.cancel')"
      :loading-text="$t('commons.loading')"

      :submit-title="`Aggiungi ${user.name} ad un gruppo`"
      submit-success-message="Utente aggiunto al gruppo"

      :fields="fields"
      :field-default-options="fieldDefaultOptions"

      :submit-promise-callback="create"
      @close="close"
  >
    <template #field.groupId="{ errors, label, classes }">
      <v-autocomplete
          :label="label"
          outlined
          :class="classes"
          v-model="data.groupId"
          :items="groups"
          :error-messages="errors"
      />
    </template>
  </action-modal>
</template>

<script>
import {ActionModal} from '@simple-it/shared-vuetify'

export default {
  name: 'AddUserToGroupModal',
  components: { ActionModal },
  props: {
    ftClientId: {
      type: String,
      required: true
    },
    user: {
      type: Object
    }
  },
  data () {
    return {
      modal: false,
      groups: [],
      data: {
        groupId: ''
      },
      fieldDefaultOptions: {
        lessMargin: true,
        cols: 12,
        // md: 6
      }
    }
  },
  mounted () {
    this.loadGroups()
  },
  computed: {
    fields () {
      return [
        { value: 'groupId', label: 'Gruppo', rules: 'required' }
      ]
    },
    show: {
      get () {
        return this.user != null
      },
      set () {
        this.$emit('update:user', null)
        this.$emit('close')
      }
    }
  },
  methods: {
    create () {
      const email = this.user.user.email
      return this.$http.auth.post(
          `/user-groups/${this.data.groupId}/users`,
          { email: email }
      )
    },
    close () {
      this.$emit('close')
    },
    async loadGroups () {
      const res = await this.$http.auth.get('/user-groups')
      this.groups = res.data.map(ug => ({ text: ug.name, value: ug.id }))
    }
  }
}
</script>
