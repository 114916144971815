import {configure, extend, setInteractionMode, ValidationObserver} from 'vee-validate'
import Vue from "vue";
import i18n from '@/plugins/i18n'

import {between, email, length, max, max_value, min, min_value, required} from 'vee-validate/dist/rules'

Vue.component('validation-observer', ValidationObserver)

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values)
  }
})

setInteractionMode('eager')

extend('required', required)
extend('email', email)
extend('min', min)
extend('min_value', min_value)
extend('max_value', max_value)
extend('length', length)
extend('between', between)
extend('max', max)
