<template>
  <div>
    <div class="mt-2 mb-5 text-h4">Test Page</div>
    <div>
      <v-text-field outlined class="mx-5" v-model="macAddress"></v-text-field>
      <v-btn @click="createDevice">Create device</v-btn>
      <v-divider class="my-6"></v-divider>
      <v-btn @click="makeTestPurchase">Make test purchase 50 cents</v-btn>

      <v-btn @click="test">Test</v-btn>
      {{res}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'TestLocalPage',
  components: {},
  data () {
    return {
      macAddress: '12:34:56:78:90:AB',
      res: null
    }
  },

  methods: {
    async createDevice () {
      await this.$http.device.post('commands/devices/create/foodytek-pk', {
        macAddress: this.macAddress
      })
      await this.$dialog.notify.success('Device created')
    },
    async makeTestPurchase () {
      const res = await this.$http.device.post('connections/2022-PK-0008/purchase', {
        amount: 50
      })
      console.log('Result: ', res.data)
    }
  }
}
</script>
