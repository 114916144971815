import axios from 'axios'
import Vue from 'vue'
import i18n from '@/plugins/i18n'
import {store} from '@/store/store'
import apiDomainPostfix from '@/api-domain-postfix.json'

const authBaseUrl = '/api/v1'
const deviceBaseUrl = '/api/v1'
const paymentBaseUrl = '/api/v1'

// ---------------- Use localhost setting ----------------
const useApiLocalhostUrl = true

const isLocalhost = location.hostname === 'localhost'
const domainPostfix = location.hostname.split('.').slice(1).join('.')
const isDev = domainPostfix !== apiDomainPostfix.prod

Vue.prototype.$env = {
  isLocalhost,
  isDev,
  domainPostfix
}

const apiUrl = (!isLocalhost ? 'https://api.' + domainPostfix
        : useApiLocalhostUrl
            ? 'http://localhost:8003'
            : 'https://api.' + apiDomainPostfix.dev
)

const authUrl = (!isLocalhost ? 'https://auth.' + domainPostfix
        : useApiLocalhostUrl
            ? 'http://localhost:8000'
            : 'https://auth.' + apiDomainPostfix.dev
) + authBaseUrl

const deviceUrl = (!isLocalhost ? 'https://device.' + domainPostfix
        : useApiLocalhostUrl
            ? 'http://localhost:8001'
            : 'https://device.' + apiDomainPostfix.dev
) + deviceBaseUrl

const paymentUrl = (!isLocalhost ? 'https://payment.' + domainPostfix
        : useApiLocalhostUrl
            ? 'http://localhost:8002'
            : 'https://payment.' + apiDomainPostfix.dev
) + paymentBaseUrl

const reportsUrl = (!isLocalhost ? `https://api.${domainPostfix}/reports`
        : useApiLocalhostUrl
            ? 'http://localhost:8003'
            : `https://api.${apiDomainPostfix.dev}/reports`
)

const headers = {
  // todo: change language when user changes language
  'Accept-Language': store.getters.language === 'en' ? 'en-us' : 'it-it'
}
if (store.getters.token) {
  headers.Authorization = 'Bearer ' + store.getters.token
}

const api = axios.create({
  baseURL: apiUrl,
  headers
})
const auth = axios.create({
  baseURL: authUrl,
  headers
})
const device = axios.create({
  baseURL: deviceUrl,
  headers
})
const payment = axios.create({
  baseURL: paymentUrl,
  headers
})
const reports = axios.create({
  baseURL: reportsUrl,
  headers
})

const noCodeCallback = () => {
}

function showError (text, callback) {
  Vue.prototype.$dialog.confirm({
    title: i18n.t('commons.attention'),
    text,
    icon: false,
    type: 'error',
    actions: [ i18n.t('commons.close') ]
  })
      .then(callback || noCodeCallback)
      .catch(callback || noCodeCallback)
}

export function preventNextErrDialog () {
  preventNextErrorDialog = true
}

let preventNextErrorDialog = false
let errorOpen = false

const interceptorFulfilled = response => response
const interceptorRejected = error => {
  if (!error.response) {
    if (!errorOpen) {
      errorOpen = true
      showError(i18n.t('commons.noServerConnection'), () => {
        errorOpen = false
      })
    }
  } else if (error.response.status === 401 && store.getters.isAuthenticated) {
    if (!errorOpen) {
      errorOpen = true
      showError(i18n.t('commons.sessionExpired'), () => {
        errorOpen = false
      })
      store.commit('logoutUser')
    }
  } else if (error.response.status === 403) {
    if (!errorOpen) {
      // showError(i18n.t('commons.sessionExpired'), () => { // TODO: Add language to forbidden http error
      showError('Permission denied to perform this action', () => {
        errorOpen = false
      })
    }
  } else {
    if (preventNextErrorDialog) {
      preventNextErrorDialog = false
    } else {
      showError(error.response.data.message || i18n.t('commons.genericError'))
    }
  }

  return Promise.reject(error)
}

// api.interceptors.response.use(interceptorFulfilled, interceptorRejected)
auth.interceptors.response.use(interceptorFulfilled, interceptorRejected)
device.interceptors.response.use(interceptorFulfilled, interceptorRejected)
payment.interceptors.response.use(interceptorFulfilled, interceptorRejected)
api.interceptors.response.use(interceptorFulfilled, interceptorRejected)
reports.interceptors.response.use(interceptorFulfilled, interceptorRejected)

export default {
  api,
  auth,
  device,
  payment,
  reports,
  $settings: {
    apiDomainPostfix,
  }
  // l: {
  //   auth: AuthApiClient,
  //   device: DeviceApiClient(),
  // }
}
