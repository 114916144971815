var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('action-modal',{attrs:{"data":_vm.obj,"cancel-btn-text":"Cancel","loading-text":"Updating balance...","submit-title":("Charge " + (_vm.user.surname) + " " + (_vm.user.name) + "'s Virtual Key"),"submit-success-message":"Virtual key charge successful!","submit-btn-text":"Purchase","submit-promise-callback":_vm.chargeVK,"fields":[{
        label: 'Amount (in cents)',
        value: 'amount',
        rules: 'required|min:1',
        cols: '12',
        sm: '6'
    },
    {
        label: 'Serial number',
        value: 'serialNumber',
        rules: 'required|min:1',
        cols: '12',
        sm: '6'
    }]},scopedSlots:_vm._u([{key:"field.serialNumber",fn:function(ref){
    var errors = ref.errors;
    var label = ref.label;
    var classes = ref.classes;
return [_c('v-autocomplete',{class:classes,attrs:{"label":label,"outlined":"","items":_vm.devices,"error-messages":errors},model:{value:(_vm.obj.serialNumber),callback:function ($$v) {_vm.$set(_vm.obj, "serialNumber", $$v)},expression:"obj.serialNumber"}})]}}],null,false,3692629090),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }