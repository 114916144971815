<script>
import {mapGetters} from "vuex";

export default {
  name: 'TopUpAndGift',
  data () {
    return {
      signUpGiftFormValid: false,
      signUpGift: {
        enable: false,
        amount: null,
      },
      allowedValsFormValid: false,
      allowedValues: [],
      thresholds: [],
      groups: [],
      types: [ 'EURO', 'PERCENTUALE' ]
    };
  },
  computed: {
    filteredGroups() {
      // Always include "No Group" option
      const noGroupOption = { id: null, name: 'Nessun gruppo' };
      return [noGroupOption, ...this.groups];
    },
    sortedThresholds () {
      return this.thresholds.slice().sort((a, b) => a.amount - b.amount);
    },
    ...mapGetters(['clientId'])
  },
  mounted () {
    this.fetchSignUpGift()
    this.fetchAllowedValues()
    this.fetchThresholds()
    this.fetchGroups()
  },
  methods: {
    async fetchSignUpGift() {
      const res = await this.$http.payment.get(`/top-ups/auto-gifts/${this.clientId}/sign-up`)
      this.signUpGift = {
        enable: res.data.enableSignUpGift,
        amount: res.data.signUpGiftAmount / 100 || null
      }
    },
    async fetchAllowedValues () {
      const res = await this.$http.payment.get(`virtual-keys/admin/top-up/values`)
      this.allowedValues = res.data.map(val => ({
        amount: val.amount / 100,
        gift: val.gift
      }))
    },
    async fetchThresholds () {
      const res = await this.$http.payment.get(`/top-ups/auto-gifts/${this.clientId}/thresholds`)
      res.data.forEach(threshold => {
        threshold.amount /= 100
        threshold.value /= 100
        threshold.valid = true
      })
      this.thresholds = res.data
    },
    async fetchGroups () {
      try {
        const response = await this.$http.auth.get('/user-groups')
        this.groups = response.data
      } catch (e) {
      }
    },

    saveSignUpGift () {
      this.$http.payment.put(`/top-ups/auto-gifts/${this.clientId}/sign-up`, {
        enableSignUpGift: this.signUpGift.enable,
        signUpGiftAmount: this.signUpGift.amount * 100
      })
      this.$dialog.notify.success('Regalo di registrazione salvato')
    },
    addThreshold () {
      this.confirmAllThresholds(false)
      this.thresholds.push({
        id: 0,
        amount: null,
        type: 'EURO',
        value: null,
        groupId: null
      });
    },
    addAllowedVal () {
      this.allowedValues.push({ amount: null, gift: 0 })
    },
    removeAllowedVal (index) {
      this.allowedValues.splice(index, 1)
    },
    async removeThreshold (threshold) {
      const that = this
      this.$dialog.confirm({
        title: 'Conferma',
        text: 'Sei sicuro di voler eliminare questa soglia di regalo?',
        actions: [
          { text: 'Annulla', color: 'grey' },
          {
            text: 'Elimina', color: 'red', handler: async () => {
              await that.$http.payment.delete(`/top-ups/auto-gifts/${that.clientId}/thresholds/${threshold.id}`)
              that.$dialog.notify.success('Soglia di regalo eliminata')
              that.fetchThresholds()
              that.fetchAllowedValues()
            }
          }
        ]
      })
    },
    confirmAllThresholds (fetchOpt = true) {
      let updated = false
      this.thresholds.forEach(async threshold => {
        if (!threshold.update || !threshold.amount || !threshold.value) return
        updated = true

        await this.$http.payment.put(`/top-ups/auto-gifts/${this.clientId}/thresholds/${threshold.id}`, {
          amount: threshold.amount * 100,
          type: threshold.type,
          value: threshold.value * 100,
          groupId: threshold.groupId
        })
        this.$dialog.notify.success('Soglia di regalo salvata')
        threshold.update = false
      })

      setTimeout(() => {
        if (fetchOpt && updated) {
          this.fetchThresholds()
        }
        this.fetchAllowedValues()
      }, 300)
    },
    async saveAllowedVal () {
      const vals = this.allowedValues.map(val => val.amount * 100)
      await this.$http.payment.put('/virtual-keys/admin/top-up/values', vals)
      this.$dialog.notify.success('Importi di ricarica salvati')
      this.fetchAllowedValues()
    },
  }
};
</script>

<template>
  <v-container>
    <h1>Ricarica regalo con registrazione su Apollo</h1>

    <div class="my-1 text-body-2">
      Imposta un importo che verrà regalato automaticamente all’utente al momento della registrazione sull'app Apollo.
    </div>

    <v-form ref="form" v-model="signUpGiftFormValid" class="d-flex align-center">
      <v-switch v-model="signUpGift.enable"/>
      <v-text-field
          v-model="signUpGift.amount"
          :disabled="!signUpGift.enable"
          style="max-width: 10rem"
          class="mx-3"
          label="Importo regalo"
          type="number"
          required
          :rules="[v => !!v || 'Richiesto', v => v > 0 || 'Deve essere maggiore di 0', v => v <= 100 || 'Deve essere minore o uguale a 100']"
          append-icon="fa-euro-sign"
      />

      <v-btn class="ml-3" :disabled="!signUpGiftFormValid || !signUpGift.amount" @click="saveSignUpGift" color="success">Salva</v-btn>
    </v-form>

    <v-divider class="my-10"/>

    <h1>Pulsanti di ricarica carta preimpostati per l’utente, su Apollo</h1>

    <div class="my-1 text-body-2">
      Imposta qui gli importi di ricarica visualizzati sull'app Apollo, a tuo piacimento. L'utente potrà scegliere tra questi importi per ricaricare la propria carta.
    </div>

    <div class="my-1 text-body-2">
      <strong>Nota:</strong> Il regalo è simulato per una persona che non appartiene a nessun gruppo ed è il valore che l'utente riceverà in più rispetto all'importo di ricarica scelto.
    </div>

    <v-form ref="form" v-model="allowedValsFormValid">
      <v-list>
        <v-list-item v-for="(av, index) in allowedValues" v-bind:key="index">
          <v-btn icon x-small class="mr-2" @click="removeAllowedVal(index)">
            <v-icon left>fa-circle-minus</v-icon>
          </v-btn>
          <v-text-field
              v-model="av.amount"
              style="max-width: 8rem"
              label="Importo ricarica"
              type="number"
              required
              :rules="[v => !!v || 'Richiesto', v => v >= 10 || 'Deve essere maggiore o uguale a €10', v => v <= 100 || 'Deve essere minore o uguale a €100']"
              append-icon="fa-euro-sign"
          />
          <span v-if="av.gift" class="ml-3 text-h6">+ {{ formatMoney(av.gift) }} regalo</span>

        </v-list-item>
      </v-list>
    </v-form>

    <div class="mt-3">
      <v-btn outlined @click="addAllowedVal" :disabled="allowedValues.length >= 4" color="primary">
        <v-icon left>fa-circle-plus</v-icon>
        Aggiungi pulsante ricarica
      </v-btn>
      <v-btn class="ml-3" :disabled="!allowedValsFormValid || allowedValues.length !== 4" @click="saveAllowedVal" color="success">Salva</v-btn>
    </div>
    <div v-if="allowedValues.length >= 4" class="mt-2">
      Non puoi più aggiungere un importo. Devono essere esattamente 4.
    </div>
    <div v-if="allowedValues.length < 4" class="mt-2">
      Aggiungi 4 importi per salvare.
    </div>

    <v-divider class="my-10"/>

    <h1>Imposta i regali/bonus automatici</h1>

    <div v-if="!thresholds.length" class="mt-4 mb-5">
      Nessuna soglia di regalo impostata. Aggiungine una per iniziare.
    </div>
    <v-row v-for="(threshold, index) in thresholds" :key="index" class="align-center my-1">
      <v-col cols="12" sm="3" md="2">
        <v-text-field
            v-model="threshold.amount"
            @input="threshold.update = true"
            label="Soglia (Euro)"
            type="number"
            required
            :rules="[v => !!v || 'Richiesto', v => v > 0 || 'Deve essere maggiore di 0']"
            append-icon="fa-euro-sign"
            class="mb-n5"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="d-flex justify-center">
        <v-btn-toggle v-model="threshold.type" tile>
          <v-btn value="EURO" @click="threshold.update = true">
            <v-icon left>fa-euro-sign</v-icon>
            Euro
          </v-btn>
          <v-btn value="PERCENTAGE" @click="threshold.update = true">
            <v-icon left>fa-percent</v-icon>
            Perc.
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-text-field
            v-model="threshold.value"
            @input="threshold.update = true"
            :label="threshold.type === 'PERCENTAGE' ? 'Valore (%)' : 'Valore (Euro)'"
            type="number"
            required
            :rules="[v => !!v || 'Richiesto', v => v > 0 || 'Deve essere maggiore di 0']"
            :append-icon="threshold.type === 'PERCENTAGE' ? 'fa-percent' : 'fa-euro-sign'"
            class="mb-n5"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="8" md="3">
        <v-autocomplete
            v-model="threshold.groupId"
            :items="filteredGroups"
            item-text="name"
            item-value="id"
            hide-no-data
            label="Gruppo"
            dense
            @input="threshold.update = true"
            class="mb-n5"
        />
      </v-col>
      <v-col cols="12" sm="4" md="2" class="d-flex align-center justify-center">
        <v-btn @click="confirmAllThresholds(true)" v-if="threshold.update" color="green" icon class="mr-2">
          <v-icon>fa-check</v-icon>
        </v-btn>

        <v-btn @click="removeThreshold(threshold)" color="red" icon>
          <v-icon>fa-trash-alt</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-divider/>
      </v-col>
    </v-row>
    <div class="mt-3">
      <v-btn outlined @click="addThreshold" color="primary">Aggiungi Soglia</v-btn>
    </div>
  </v-container>
</template>
