<template>
  <div v-if="device">
    <tab-layout title="Informazioni generali" row-content @reload="reload(loadDetails)">
      <ft-col-info-view label="Nome" :value="device.name"/>
      <ft-col-info-view label="Descrizione" :value="device.description"/>

      <ft-col-info-view>
        <template v-slot:label>
          <span>
            Partner
            <v-icon small class="mx-1">fa-arrow-right</v-icon>
            Sede
          </span>
        </template>
        <div class="d-flex flex-wrap">
          <div v-if="device.partner.id">
            <v-tooltip bottom v-if="isAdmin">
              <template v-slot:activator="{ on }">
                <v-chip link outlined v-on="on" @click="copyTextToClipboard(device.partner.id)">
                  {{ device.partner.name }}
                </v-chip>
              </template>
              <span>Click to copy id <br> <b>{{ device.partner.id }}</b></span>
            </v-tooltip>
            <v-chip v-else link outlined :to="'/partners/' + device.partner.id">{{ device.partner.name }}</v-chip>
          </div>
          <div v-else>-</div>
          <v-icon small class="mx-2">fa-arrow-right</v-icon>
          <div v-if="device.location.id">
            <v-tooltip bottom v-if="isAdmin">
              <template v-slot:activator="{ on }">
                <v-chip link outlined v-on="on" @click="copyTextToClipboard(device.location.id)">
                  {{ device.location.name }}
                </v-chip>
              </template>
              <span>Click to copy id <br> <b>{{ device.location.id }}</b></span>
            </v-tooltip>
            <v-chip v-else link outlined :to="'/locations/' + device.location.id">{{ device.location.name }}</v-chip>
          </div>
          <div v-else>-</div>
        </div>
      </ft-col-info-view>

      <ft-col-info-view label="Versione"
                        :value="device.version ? `${device.version} (${formatDateTime(device.lastVersionUpdate)})` : ''"
                        no-value-text="Non ancora ricevuta dal dispositivo"/>
      <ft-col-info-view v-if="isAdmin" label="Cliente">
        <span v-if="device.ftClient.id">
          <v-chip link outlined @click="$router.push('/ft-clients/' + device.ftClient.id)">
            {{ device.ftClient.displayName }}
          </v-chip>
        </span>
        <span v-else>
          <v-btn color="primary" @click="openAssignDeviceModal">Assegna</v-btn>
        </span>
      </ft-col-info-view>
      <ft-col-info-view label="Configurato">
        <boolean-icon :value="device.configured"/>
      </ft-col-info-view>
      <ft-col-info-view label="Testato" no-value-text="Dato non ancora disponibile">
        <!--          <v-icon :color="device.configured ? 'green' : 'red'">{{ device.configured ? 'fa-check' : 'fa-times' }}</v-icon>-->
      </ft-col-info-view>
      <ft-col-info-view label="Attivo">
        <boolean-icon :value="device.active"/>
      </ft-col-info-view>
      <ft-col-info-view label="QR code">
        <v-btn color="primary" outlined @click="openQRCode">Mostra QR</v-btn>
      </ft-col-info-view>
      <ft-col-info-view label="Reset dispositivo" v-if="isAdmin">
        <v-btn color="warning" @click="resetDevice">Reset</v-btn>
      </ft-col-info-view>

      <ft-col-info-view label="Accesso facilitato" single-edit :edit-callback="editEasyAccess">
        <boolean-icon :value="device.easyAccessEnabled"/>
        <span class="ml-2">{{ easyAccessType }}</span>

        <template v-slot:edit>
          <div class="d-flex">
            <v-switch v-model="device.easyAccessEnabled"/>
            <v-select v-model="device.easyAccessType" :disabled="!device.easyAccessEnabled" class="ml-2"
                      :items="easyAccessTypesList"/>
          </div>
        </template>
      </ft-col-info-view>
    </tab-layout>

    <tab-layout title="Configurazione" :row-content="!!config">
      <div v-if="!config">
        Dispositivo non ancora configurato
      </div>
      <div v-else>
        <v-row>
          <ft-col-info-view label="Parallel / Pulses" :value="config.parallel ? 'Parallel' : 'Pulses'"/>
          <ft-col-info-view label="Coin reader">
            <boolean-icon :value="config.coinReader" class="mr-1"/>
            <span v-if="config.coinReader">
            {{ config.coinReaderBinary ? 'Binary' : 'Channel' }}
            (inhibition {{ config.coinInhibitionHigh ? '1' : '0' }})
          </span>
          </ft-col-info-view>
          <ft-col-info-view label="Banknote reader">
            <boolean-icon :value="config.banknoteReader" class="mr-1"/>
            <span v-if="config.banknoteReader">
            {{ config.banknoteReaderBinary ? 'Binary' : 'Channel' }}
            (inhibition {{ config.banknoteInhibitionHigh ? '1' : '0' }})
          </span>
          </ft-col-info-view>
          <ft-col-info-view label="Top up enabled">
            <boolean-icon :value="config.topUpEnabled"/>
          </ft-col-info-view>
          <ft-col-info-view label="Unit price" :value="config.unitPrice + ' cents'"/>
          <ft-col-info-view label="Max purchase amount" :value="formatMoney(config.maxPurchaseAmount)"/>

          <ft-col-info-view v-if="config.coinChannelValues"
                            label="Coin channel values" :value="config.coinChannelValues.join(', ')"/>
          <ft-col-info-view v-if="config.banknoteChannelValues"
                            label="Banknote channel values" :value="config.banknoteChannelValues.join(', ')"/>

          <ft-col-info-view label="Executive">
            <boolean-icon :value="config.executive"/>
          </ft-col-info-view>
        </v-row>

        <div v-if="config.executive">
          <h3 class="mb-4 mt-10">Executive configuration</h3>
          <v-row>
            <ft-col-info-view label="Decimal point position" :value="String(config.decimalPointPosition)"/>
            <ft-col-info-view label="Maximum credit" :value="formatMoney(config.maximumCredit)"/>
            <ft-col-info-view label="Credit timeout" :value="config.creditTimeoutMinutes + ' minutes'"/>
          </v-row>
        </div>
      </div>
    </tab-layout>

    <assign-device-modal
        :serial-number.sync="assignDeviceSerialNumber"
        @close="reload"
    />

    <device-q-r-code-modal v-model="qrCode"/>
  </div>
</template>

<script>
import TabLayout from "@/components/UIAccessories/TabLayout.vue";
import DeviceQRCodeModal from "@/components/modals/commons/DeviceQRCodeModal.vue";
import FtColInfoView from "@/components/UIAccessories/FtColInfoView.vue";
import {mapGetters} from "vuex";
import AssignDeviceModal from "@/components/modals/administration/AssignDeviceModal.vue";
// import { preventNextErrDialog } from '@/plugins/axios'

export default {
  name: 'Information',
  components: { AssignDeviceModal, FtColInfoView, DeviceQRCodeModal, TabLayout },
  props: {
    serialNumber: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      device: null,
      config: null,
      qrCode: {
        show: false,
        macAddress: null,
        serialNumber: null,
      },
      assignDeviceSerialNumber: null,
      easyAccessTypesList: [
        { text: 'Porta', value: 'DOOR' },
        { text: 'Sbarra parcheggio', value: 'PARKING_BARRIER' },
        { text: 'Golf Cart', value: 'GOLF_CART' }
      ],
    }
  },
  mounted () {
    this.loadDetails()
  },
  computed: {
    ...mapGetters([ 'isAdmin' ]),
    easyAccessType () {
      return this.easyAccessTypesList.find(e => e.value === this.device.easyAccessType)?.text || ''
    }
  },

  methods: {
    async reload (load) {
      setTimeout(load, 800)
    },
    async loadDetails () {
      const res = await this.$http.device.get('devices/' + this.serialNumber)
      this.device = res.data

      if (this.device.configured) {
        this.loadConfig().then()
      }
    },
    async loadConfig () {
      // preventNextErrDialog()
      try {
        const res = await this.$http.device.get(`foodytek-pk/${this.serialNumber}/configuration`)
        this.config = res.data
      } catch (e) {
      }
    },
    async editEasyAccess () {
      try {
        await this.$http.device.patch(`easy-access/${this.device.serialNumber}`, {
          enabled: this.device.easyAccessEnabled,
          type: this.device.easyAccessType
        })
      } catch (e) {
      }
    },
    openQRCode () {
      console.log(this.device.macAddress)
      this.qrCode.macAddress = this.device.macAddress
      this.qrCode.serialNumber = this.serialNumber
      this.qrCode.show = true
    },
    openAssignDeviceModal () {
      this.assignDeviceSerialNumber = this.serialNumber
    },
    resetDevice () {
      try {
        const that = this
        this.$dialog.confirm({
          title: 'Reset device',
          text: 'Are you sure you want to reset this device? <b>The operation is irreversible!</b>',
          actions: [
            { text: 'Cancel' },
            {
              text: 'Reset',
              color: 'warning',
              outlined: true,
              async handler () {
                await that.$http.device.delete(`foodytek-pk/${that.serialNumber}/reset`)
                setTimeout(() => {

                  that.loadDetails()
                  that.config = null
                }, 600)
              }
            }
          ]
        })
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
