<script>
export default {
  name: 'FtDateField',
  props: {
    value: {
      type: String,
      required: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dateMenu: false
    }
  },
  computed: {
    input: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
  }
}
</script>

<template>
  <div>
    <v-menu
        ref="dateMenu"
        v-model="dateMenu"
        transition="scroll-y-transition"
        offset-y
        :close-on-content-click="false"
        solo
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :value="formatDate(input)"
            prepend-inner-icon="fa-calendar"
            v-bind="attrs"
            v-on="on"
            readonly
            :outlined="outlined"
            :dense="dense"
            :hide-details="hideDetails"
            :error="error"
        />
      </template>
      <v-date-picker
          v-model="input"
          no-title
          :first-day-of-week="1"
          :locale="$store.getters.language"
          @input="dateMenu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
