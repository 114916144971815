<template>
  <div>
    <div class="text-h4">
      <span v-if="isAdmin">Devices management</span>
      <span v-else>I miei dispositivi</span>
    </div>

    <div v-if="isAdmin">
      <div class="text-overline text-left">
        Select the columns to show:
        <v-checkbox
            class="d-inline-block mx-2 mt-0"
            @change="newVal => headers.forEach(item => item.visible = newVal)"
            label="Select all"
        ></v-checkbox>
      </div>
      <v-checkbox
          class="d-inline-block mx-2 mt-0"
          v-for="column in headers"
          v-bind:key="column.value"
          v-model="column.visible"
          :label="column.text"
          @change="updateHeadersVisibility"
      ></v-checkbox>
    </div>

    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cerca tra i dispositivi..."
        single-line
        hide-details
        outlined
        class="mt-3 mb-6 mx-5"
    />

    <v-card outlined>
      <v-data-table
          :headers="visibleHeaders"
          :items="devices"
          :search="search"
          :custom-filter="customFilter"
          item-key="serialNumber"
          sort-by="serialNumber"
          sort-desc
          class="text-center"
      >
        <template v-slot:item.serialNumber="{ value }">
          <v-chip link outlined :to="`/devices/${value}`">{{ value }}</v-chip>
        </template>

        <template v-slot:item.active="{ value }">
          <v-icon :color="value ? 'green' : 'red'">{{ value ? 'fa-check' : 'fa-times' }}</v-icon>
        </template>
        <template v-slot:item.configured="{ value }">
          <v-icon :color="value ? 'green' : 'red'">{{ value ? 'fa-check' : 'fa-times' }}</v-icon>
        </template>

        <template v-slot:item.ftClient="{ value }">
          <v-tooltip bottom v-if="value">
            <template v-slot:activator="{ on }">
              <v-chip link outlined v-on="on" @click="copyTextToClipboard(value.id)">{{ value.businessName }}</v-chip>
            </template>
            <span>Click to copy id <br> <b>{{ value.id }}</b></span>
          </v-tooltip>
        </template>

        <template v-slot:item.partner="{ value }">
          <div v-if="value">
            <v-tooltip bottom v-if="isAdmin">
              <template v-slot:activator="{ on }">
                <v-chip link outlined v-on="on" @click="copyTextToClipboard(value.id)">{{ value.name }}</v-chip>
              </template>
              <span>Click to copy id <br> <b>{{ value.id }}</b></span>
            </v-tooltip>
            <v-chip v-else link outlined :to="'/partners/' + value.id">{{ value.name }}</v-chip>
          </div>
        </template>

        <template v-slot:item.location="{ value }">
          <div v-if="value">
            <v-tooltip bottom v-if="isAdmin">
              <template v-slot:activator="{ on }">
                <v-chip link outlined v-on="on" @click="copyTextToClipboard(value.id)">{{ value.name }}</v-chip>
              </template>
              <span>Click to copy id <br> <b>{{ value.id }}</b></span>
            </v-tooltip>
            <v-chip v-else link outlined :to="'/locations/' + value.id">{{ value.name }}</v-chip>
          </div>
        </template>

        <template v-slot:item.lastVersionUpdate="{ item }">
          {{ formatDateNoSecs(item.lastVersionUpdate) }}
        </template>
        <template v-slot:item.creationDate="{ item }">
          {{ formatDateNoSecs(item.creationDate) }}
        </template>
        <template v-slot:item.assignmentDate="{ item }">
          {{ formatDateNoSecs(item.assignmentDate) }}
        </template>
        <template v-slot:item.activationDate="{ item }">
          {{ formatDateNoSecs(item.activationDate) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-btn
                v-if="isAdmin && !item.ftClient"
                icon
                color="green"
                @click="openAssignDeviceModal(item)"
            >
              <v-icon small>fa-user-check</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="primary"
                       @click="openQRCode(item)"
                       v-bind="attrs"
                       v-on="on"
                >
                  <v-icon>
                    fa-qrcode
                  </v-icon>
                </v-btn>
              </template>
              <span>Download QR-Code</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="primary"
                       @click="copyNFCUrl(item.macAddress)"
                       v-bind="attrs"
                       v-on="on"
                >
                  <v-icon>
                    fa-brands fa-nfc-symbol
                  </v-icon>
                </v-btn>
              </template>
              <span>Copy NFC Tag URL</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <DeviceQRCodeModal v-model="qrCode"/>

    <assign-device-modal
        :serial-number.sync="assignDeviceSerialNumber"
        @close="reload"
    />

    <v-snackbar v-model="snackbar">
      NFC Tag URL copied to clipboard
      <template v-slot:action="{ attrs }">
        <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import DeviceQRCodeModal from '@/components/modals/commons/DeviceQRCodeModal.vue'
import AssignDeviceModal from "@/components/modals/administration/AssignDeviceModal.vue";
import {mapGetters} from "vuex";

export default {
  name: 'DevicesManagement',
  components: {
    AssignDeviceModal,
    DeviceQRCodeModal
  },
  data () {
    return {
      devices: [],
      search: '',
      headers: [
        {
          text: 'Serial Number',
          value: 'serialNumber',
          visible: true
        },
        {
          text: 'MAC Address',
          value: 'macAddress',
          visible: this.isAdmin
        },
        {
          text: 'Version',
          value: 'version',
          visible: true
        },
        {
          text: 'Last version update',
          value: 'lastVersionUpdate',
          filterable: false
        },
        {
          text: 'Client',
          value: 'ftClient',
          sortable: false,
          visible: !this.isAdmin // hidden by default for admins
        },
        {
          text: 'Partner',
          value: 'partner',
          sortable: false,
          visible: true
        },
        {
          text: 'Location',
          value: 'location',
          sortable: false,
          visible: !this.isAdmin // hidden by default for admins
        },
        {
          text: 'Activation name',
          value: 'name',
          sortable: false,
          visible: true
        },
        {
          text: 'Activation description',
          value: 'description',
          sortable: false
        },
        {
          text: 'Creation date',
          value: 'creationDate',
          filterable: false,
        },
        {
          text: 'Assignment date',
          value: 'assignmentDate',
          filterable: false
        },
        {
          text: 'Activation date',
          value: 'activationDate',
          visible: !this.isAdmin, // hidden by default for admins
          filterable: false
        },
        {
          text: 'Active',
          value: 'active',
          visible: true,
          filterable: false
        },
        {
          text: 'Configured',
          value: 'configured',
          visible: true,
          filterable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          visible: true,
          filterable: false
        }
      ],
      transactions: {
        show: false,
        serialNumber: null,
        data: null,
        monthMenu: false,
        selectedDate: null
      },
      qrCode: {
        show: false,
        macAddress: null
      },
      assignDeviceSerialNumber: null,
      snackbar: false
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isClient'
    ]),
    visibleHeaders () {
      return this.headers.filter(item => item.visible)
    }
  },
  mounted () {
    this.getDevices()
  },
  methods: {
    reload () {
      setTimeout(this.getDevices, 200)
    },
    async getDevices () {
      const url = this.isAdmin
          ? 'devices'
          : 'devices/ft-client'
      const response = await this.$http.device.get(url)
      this.devices = response.data
    },
    customFilter (value, search) {
      switch (typeof value) {
        case "undefined":
          return false
        case "object":
          for (const valueKey in value) {
            if (this.customFilter(value[ valueKey ], search)) return true
          }
          return false
        case "string":
          return value.toLowerCase().includes(search.toLowerCase())
        default:
          return false
      }
    },
    async monthSelected () {
      const [ year, month ] = this.transactions.selectedDate.split('-')
      await this.getDevicesTransactions(year, month)
    },
    openAssignDeviceModal (device) {
      this.assignDeviceSerialNumber = device.serialNumber
    },
    formatDate (dateNumber) {
      if (!dateNumber) return 'No date'
      return new Date(dateNumber * 1000).toLocaleString(this.$store.getters.language)
    },
    formatMoney (value) {
      return (value / 100).toFixed(2) + ' €'
    },
    updateHeadersVisibility () {
      const visible = {}
      this.headers.forEach(item => {
        visible[ item.value ] = item.visible
      })
    },
    openQRCode (item) {
      this.qrCode.macAddress = item.macAddress
      this.qrCode.serialNumber = item.serialNumber
      this.qrCode.show = true
    },
    copyNFCUrl (mac) {
      const url = 'foodytek:device=foodytek-' + mac
      navigator.clipboard.writeText(url)
      this.snackbar = true
    }
  }
}
</script>

<style scoped>

</style>
