<template>
<div id="active-devices" class="ma-6">
  <v-row class="justify-center mt-2 mb-4">
    <span class="display-3">
      {{ $t('usersVirtualKeys.title') }}
    </span>
  </v-row>

  <user-virtual-keys-table :ft-client-id="$store.getters.clientId"/>
</div>
</template>

<script>
import UserVirtualKeysTable from "@/components/UserVirtualKeysTable.vue";

export default {
  name: 'UserWallet',
  components: { UserVirtualKeysTable }
}
</script>

<style scoped>

</style>
