<template>
  <div>
    <div class="text-center">
      <v-btn
          color="primary"
          class="mb-3"
          @click="loadData"
      >
        Load purchases
      </v-btn>
    </div>

    <v-data-table
        v-if="items !== null"
        :items="items"
        :headers="headers"
        item-key="purchaseId"
        sort-by="purchaseId"
    >
      <template v-slot:item.requestReceived="{ value }">
        {{ value ? formatDateTime(value) : '-' }}
      </template>
      <template v-slot:item.resultReceived="{ value }">
        {{ value ? formatDateTime(value) : '-' }}
      </template>
      <template v-slot:item.resultReceivedFromStatus="{ value, item }">
        <div v-if="value">
          {{ formatDateTime(value) }}<br>
          <span class="text-caption">{{ item.resultReceivedFromStatusCount }} times</span>
        </div>
        <div v-else>-</div>
      </template>

      <template v-slot:item.tooFastDiscard="{ value }">
        <!-- Show X if purchase has been discarded -->
        <boolean-icon v-if="value" :value="!value"/>
      </template>
      <template v-slot:item.charged="{ value }">
        <boolean-icon :value="value"/>
      </template>
      <template v-slot:item.vendCompleted="{ value }">
        <boolean-icon v-if="value !== null" :value="value"/>
        <span v-else class="font-italic text-caption">No result received</span>
      </template>
      <template v-slot:item.shouldBeRefundedAutomatically="{ value }">
        <v-icon v-if="value" color="warning">fa-circle-exclamation</v-icon>
      </template>

      <template v-slot:item.previousStatus="{ value }">
        <div v-if="value">
          <b>{{ formatDateTime(value.instant) }}</b>
          <br>
          <v-tooltip bottom v-if="value">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{formatDuration(value.timestamp)}}</span>
            </template>
            <span>
              Duration from last boot
              <br>
              {{ formatDateTime(value.instant - value.timestamp / 1000000) }}
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.nextStatus="{ value }">
        <div v-if="value">
          <b>{{ formatDateTime(value.instant) }}</b>
          <br>
          <v-tooltip bottom v-if="value">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{formatDuration(value.timestamp)}}</span>
            </template>
            <span>
              Duration from last boot
              <br>
              {{ formatDateTime(value.instant - value.timestamp / 1000000) }}
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.refunded="{ value, item }">
        <div v-if="value">
          Yes: {{ getReasonCode(item.refundReason) }} ({{ item.refundReason }})
        </div>
        <div v-else-if="item.charged">
          <v-btn small text color="primary"
                 @click="refundTransaction(item)"
          >
            Rimborsa
          </v-btn>
        </div>
      </template>

      <template v-slot:item.transactionId="{ value }">
        <v-tooltip bottom v-if="value">
          <template v-slot:activator="{ on }">
            <v-chip small link outlined v-on="on" @click="copyTextToClipboard(value)">Copy ID</v-chip>
          </template>
          <span>
            {{value}}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import foodytekUtils from "@/mixins/foodytekUtils";

export default {
  name: 'Purchases',
  mixins: [foodytekUtils],
  props: {
    serialNumber: {
      type: String,
      required: true
    },
    fromDate: {
      type: Object,
      required: true
    },
    toDate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: null,
      headers: [
        { value: 'purchaseId', text: 'Purchase id' },
        { value: 'requestReceived', text: 'Request received date' },
        { value: 'resultReceived', text: 'Result received date' },
        { value: 'resultReceivedFromStatus', text: 'Result received from status date' },
        { value: 'tooFastDiscard', text: 'Discarded (too fast purchase)' },
        { value: 'charged', text: 'VK charged' },
        { value: 'vendCompleted', text: 'Vend completed successfully' },
        { value: 'shouldBeRefundedAutomatically', text: 'Should refund automatically (reboot problem)' },
        { value: 'previousStatus', text: 'Prev status' },
        { value: 'nextStatus', text: 'Next status' },
        { value: 'refunded', text: 'Refunded' },
        { value: 'transactionId', text: 'Transaction id' },
      ].map(i => {
        i.sortable = false
        return i
      }),
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    getDateFromObj (d) {
      const [year, month, day] = d.date.split('-')
      const [hour, min] = d.time.split(':')

      const date = new Date()
      date.setFullYear(year, month - 1, day)
      date.setHours(hour, min)
      return date.toISOString()
    },
    getReasonMessage (reasonId) {
      const reasons = [
          'No error',
      ]

      return reasons[reasonId]
    },
    async loadData () {
      try {
        const fromDate = this.getDateFromObj(this.fromDate)
        const toDate = this.getDateFromObj(this.toDate)
        const response = await this.$http.device.get(`foodytek-pk/${this.serialNumber}/purchases`, {
          params: {
            from: fromDate,
            to: toDate
          }
        })
        this.items = response.data
      } catch (err) {
      }
    },
    async refundTransaction (item) {
      item.disabled = true
      const res = await this.$dialog.confirm({
        title: 'Rimborso transazione',
        text: `Sei sicuro di voler rimborsare questa transazione con id '${item.purchaseId}'?`,
      })
      if (!res) return

      await this.$http.payment.patch(
          `virtual-keys/admin/transactions/${item.transactionId}/refund`
      )
      setTimeout(this.getTransactions, 600)
      await this.getTransactions()
    },
  }
}
</script>

<style scoped>

</style>
