var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"devices-transaction"}},[_c('v-card',{staticClass:"mt-8 elevation-8 mx-auto mb-7",staticStyle:{"width":"90%"}},[_c('v-card-title',{staticClass:"d-block text-center pt-6 pb-5 text-h4"},[_vm._v(" "+_vm._s(this.$t('devicesTransactions.title'))+" ")]),_c('v-card-text',[_c('ft-month-select',{attrs:{"label":_vm.$t('devicesTransactions.monthPickerText').toString()},on:{"month-selected":_vm.monthSelected}}),_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.devices,"headers":_vm.headers,"item-key":"item.id"},scopedSlots:_vm._u([{key:"item.lastUpdate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value, _vm.$t('devicesTransactions.noDateText')))+" ")]}},{key:"item.purchases",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.normalPurchases",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.topUps",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }