var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{'row-pointer': !_vm.byClient},attrs:{"headers":_vm.paymentsHeaders,"items":_vm.pastPayments},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.clientCustomer",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.businessName)+" ")]}},{key:"item.total",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":_vm.getStatusColor(value)}},[_vm._v(_vm._s(_vm.getStatusIcon(value)))])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getStatusMessage(item))}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }