<template>
  <div>
    <v-btn color="primary" outlined @click="dialog = true">Escludi...</v-btn>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Escludi statistiche</v-card-title>
        <v-card-text>
          <div class="mb-4">
            Questa finestra di dialogo serve per escludere determinati periodi di transazioni. È possibile compilare
            solo uno dei due campi per escludere fino a una certa data (campo “Fine”) o da una certa data (campo
            “Inizio”). Tuttavia, è anche possibile specificare un intervallo di tempo compilando entrambi i campi.
          </div>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fromDate"
                        label="Data Inizio"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        append-icon="fa-xmark"
                        @click:append="clearField('from')"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fromDate" @input="openFromTimePicker" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu1 = false">Annulla</v-btn>
                    <v-btn text color="primary" @click="saveFromDate">OK</v-btn>
                  </v-date-picker>
                </v-menu>
                <v-menu
                    ref="timeMenu1"
                    v-model="timeMenu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fromTime"
                        label="Ora Inizio"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        v-show="fromDate"
                        append-icon="fa-xmark"
                        @click:append="clearField('fromTime')"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-model="fromTime" @input="menu1 = false">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="timeMenu1 = false">Annulla</v-btn>
                    <v-btn text color="primary" @click="saveFromTime">OK</v-btn>
                  </v-time-picker>
                </v-menu>
                <v-btn text @click="setCurrentDateTime('from')">Imposta ora attuale</v-btn>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="toDate"
                        label="Data Fine"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        append-icon="fa-xmark"
                        @click:append="clearField('to')"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="toDate" @input="openToTimePicker" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">Annulla</v-btn>
                    <v-btn text color="primary" @click="saveToDate">OK</v-btn>
                  </v-date-picker>
                </v-menu>
                <v-menu
                    ref="timeMenu2"
                    v-model="timeMenu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="toTime"
                        label="Ora Fine"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        v-show="toDate"
                        append-icon="fa-xmark"
                        @click:append="clearField('toTime')"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-model="toTime" @input="menu2 = false">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="timeMenu2 = false">Annulla</v-btn>
                    <v-btn text color="primary" @click="saveToTime">OK</v-btn>
                  </v-time-picker>
                </v-menu>
                <v-btn text @click="setCurrentDateTime('to')">Imposta ora attuale</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="dialog = false">Annulla</v-btn>
          <v-btn color="primary" @click="submit">Escludi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ExcludeFromStatsDialog',
  data () {
    return {
      dialog: false,
      from: null,
      to: null,
      fromDate: null,
      toDate: null,
      fromTime: '00:00',
      toTime: '00:00',
      menu1: false,
      menu2: false,
      timeMenu1: false,
      timeMenu2: false,
    };
  },
  methods: {
    openFromTimePicker () {
      this.menu1 = false;
      this.$nextTick(() => {
        this.$refs.timeMenu1.save(this.fromTime);
        this.timeMenu1 = true;
      });
    },
    openToTimePicker () {
      this.menu2 = false;
      this.$nextTick(() => {
        this.$refs.timeMenu2.save(this.toTime);
        this.timeMenu2 = true;
      });
    },
    saveFromDate () {
      this.menu1 = false;
      this.openFromTimePicker();
    },
    saveToDate () {
      this.menu2 = false;
      this.openToTimePicker();
    },
    saveFromTime () {
      this.timeMenu1 = false;
      this.from = this.formatDateTime(this.fromDate, this.fromTime);
    },
    saveToTime () {
      this.timeMenu2 = false;
      this.to = this.formatDateTime(this.toDate, this.toTime);
    },
    clearField (field) {
      if (field === 'from') {
        this.from = null;
        this.fromDate = null;
        this.fromTime = null;
      } else if (field === 'to') {
        this.to = null;
        this.toDate = null;
        this.toTime = null;
      } else if (field === 'fromTime') {
        this.fromTime = null;
        this.from = this.formatDateTime(this.fromDate, this.fromTime);
      } else if (field === 'toTime') {
        this.toTime = null;
        this.to = this.formatDateTime(this.toDate, this.toTime);
      }
    },
    setCurrentDateTime (dateField) {
      const currentDateTime = new Date();
      const date = currentDateTime.toISOString().substr(0, 10);
      const time = currentDateTime.toTimeString().substr(0, 5);
      if (dateField === 'from') {
        this.fromDate = date;
        this.fromTime = time;
        this.from = this.formatDateTime(date, time);
      } else if (dateField === 'to') {
        this.toDate = date;
        this.toTime = time;
        this.to = this.formatDateTime(date, time);
      }
    },
    formatDateTime (date, time) {
      if (!date || !time) {
        return null;
      }
      const [ year, month, day ] = date.split('-');
      const [ hours, minutes ] = time.split(':');
      const formattedDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));
      return formattedDate.toISOString();
      // return `${date}T${time}:00.000+00:00`;
    },
    async submit () {
      const fromISO = this.formatDateTime(this.fromDate, this.fromTime);
      const toISO = this.formatDateTime(this.toDate, this.toTime);

      // Make an API call with fromISO and toISO
      try {
        await this.yourApiFunction(fromISO, toISO);
        this.dialog = false;
      } catch (error) {
      }
    },
    async yourApiFunction (fromISO, toISO) {
      await this.$http.reports('v2/dashboard/exclude-from-stats', {
        method: 'PATCH',
        params: {
          ftClientId: this.$store.getters.clientId,
          from: fromISO ? fromISO : null,
          to: toISO ? toISO : null,
        }
      })
    }
  }
};
</script>
